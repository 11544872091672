















// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartStandardGaugeSlider from '@/components/charts/standard/ChartStandardGaugeSlider.vue';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingContainerDistance',
  components: {
    ChartStandardGaugeSlider,
    ChartContainer
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const { isImperial } = useUnit().currentUnit();
    const valid = computed(() => properties.displayMetrics.aggregates.walking_distance);
    const unit = computed(() => getUnit(EValueTypeUnit.Distance));

    return {
      // Valid
      valid,
      // Values
      isImperial,
      unit
    };
  }
});
