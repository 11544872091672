<template>
  <div class="card">
    <span class="card--title">{{ title }}</span>
    <span class="card--subtitle mb-3">{{ subtitle }}</span>
    <span class="card--data">{{ data }}</span>
  </div>
</template>

<script>
// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRehabTripleHopJumpProfileCard',
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    data: {
      type: String,
      required: true
    }
  }
});
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  background: #fafafa;
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  max-width: 200px;

  &--title {
    color: #bcbfc6;
    font-style: normal;
    font-weight: 600;
    font-style: 0.8rem;
  }

  &--subtitle,
  &--data {
    color: #020c27;
    font-style: normal;
    font-weight: 600;
    font-style: 1rem;
  }
}
</style>
