var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"patient__results"},[(_vm.validAnalysis)?_c('div',[_c('PatientResultsHeader',_vm._b({staticClass:"mb-5",on:{"setSelectedFilter":function (pathology) { return _vm.setSelectedFilter(pathology); }}},'PatientResultsHeader',{ analysis: _vm.analysis, workspace: _vm.workspace, patient: _vm.patient, degradedAnalysis: _vm.degradedAnalysis, scenario: _vm.scenario, isOnlyOrthotics: _vm.isOnlyOrthotics },false))],1):_vm._e(),('podomigration' in _vm.analysis)?_c('div',{staticClass:"podomigration-alert mb-2"},[_c('GlobalIcon',{staticClass:"mr-2",attrs:{"library":"light","icon":"lightbulb-exclamation","color":"primary","size":"16"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('podomigration.analysis.warning'))+" "),_c('a',{staticClass:"ml-1",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t('podomigration.actions.read-more')))])])],1):_vm._e(),_c('div',{staticClass:"patient__results__analysis"},[(_vm.scoringView && (_vm.showScoringWidget === 'null' || _vm.showScoringWidget === null))?_c('div',{staticClass:"scoring-banner mb-2"},[_c('div',[_c('span',{staticClass:"scoring-banner__title"},[_vm._v(" "+_vm._s(_vm.$t('commons.sentences.display-score-banner-title'))+" ")]),_c('span',{staticClass:"scoring-banner__subtitle"},[_vm._v(" "+_vm._s(_vm.$t('commons.sentences.display-score-banner-subtitle'))+" ")])]),_c('div',[_c('VBtn',{staticClass:"font-weight-semibold",attrs:{"color":"primary","depressed":"","rounded":""},on:{"click":function($event){return _vm.setScoringWidget(true)}}},[_vm._v(" "+_vm._s(_vm.$t('commons.actions.turn-on'))+" ")]),_c('VBtn',{staticClass:"ml-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.setScoringWidget(false)}}},[_c('GlobalIcon',{attrs:{"icon":"times","color":"#7E8391"}})],1)],1)]):_vm._e(),(_vm.scoringView)?_c('PatientResultsScoring',_vm._b({},'PatientResultsScoring',{
          analysis: _vm.analysis
        },false)):(_vm.orthoticsView)?_c('PatientResultsOrthotics',_vm._b({},'PatientResultsOrthotics',{
          analysis: _vm.analysis,
          scenario: _vm.scenario
        },false)):[(_vm.isWalking)?_c('PatientResultsWalking',_vm._b({},'PatientResultsWalking',{
            analysis: _vm.analysis,
            resultsTab: _vm.resultsTab,
            selectedFilter: _vm.selectedFilter,
            degradedAnalysis: _vm.degradedAnalysis,
            validAnalysis: _vm.validAnalysis,
            customSelectedCharts: _vm.customSelectedCharts,
            aggregates: _vm.aggregates,
            norms: _vm.norms,
            displayMetrics: _vm.displayMetrics
          },false)):_vm._e(),(_vm.isRunning)?_c('PatientResultsRunning',_vm._b({},'PatientResultsRunning',{
            analysis: _vm.analysis,
            aggregates: _vm.aggregates,
            segments: _vm.segments,
            norms: _vm.norms,
            displayMetrics: _vm.displayMetrics,
            resultsTab: _vm.resultsTab,
            validAnalysis: _vm.validAnalysis,
            scenario: _vm.scenario
          },false)):_vm._e(),(_vm.isRehab)?_c('PatientResultsRehab',_vm._b({},'PatientResultsRehab',{ analysis: _vm.analysis, aggregates: _vm.aggregates, displayMetrics: _vm.displayMetrics, segments: _vm.segments },false)):_vm._e(),(_vm.validAnalysis)?_c('PatientResultsFooter',_vm._b({},'PatientResultsFooter',{ analysis: _vm.analysis, degradedAnalysis: _vm.degradedAnalysis, analysisType: _vm.analysisType },false)):_vm._e()]],2),_c('DialogAnalysisConditionsPainsUpdate'),_c('DialogAnalysisConditionsWalkingAidsUpdate'),_c('DialogAnalysisConditionsShoesTypeUpdate')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }