














// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartRehabGroupTripleHopJumpProfile from '@/components/charts/rehab/groups/ChartRehabGroupTripleHopJumpProfile.vue';
import ElementResultsSwitch from '@/components/elements/ElementResultsSwitch.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRehabContainerTripleHopJumpProfile',
  components: {
    ChartContainer,
    ChartRehabGroupTripleHopJumpProfile,
    ElementResultsSwitch
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    segments: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: false
    }
  },
  setup() {
    const isAdvancedMode = ref(false);

    return {
      // Values
      isAdvancedMode
    };
  }
});
