





























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, computed } from '@vue/composition-api';
// Import components--------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartRunningSpeedCadenceDetailled from '@/components/charts/running/ChartRunningSpeedCadenceDetailled.vue';
import ChartRunningDetailledTable from '@/components/charts/running/ChartRunningDetailledTable.vue';
// Import utils ------------------------------------------------------------------------------------
import { useAnalytics } from '@/utils/analytics.utils';
import { useUnit } from '@/utils/unit.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningGroupDetailled',
  components: {
    ChartContainer,
    ChartRunningSpeedCadenceDetailled,
    ChartRunningDetailledTable
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    segments: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const { trackSuccess } = useAnalytics();
    const { currentUnit } = useUnit();
    const compareData = ref([]);

    const valid = computed(
      () =>
        !!properties.segments.running_step_cadence?.global?.avg &&
        !!properties.segments.running_speed?.global?.avg
    );

    function setSelectedPoints(data: any) {
      compareData.value = data;
      trackSuccess('PatientAnalysis/Analysis/Cadence-speed/Comparative results', {
        analysisType: 'running'
      });
    }

    return {
      // Values
      currentUnit,
      valid,
      compareData,
      // Methods
      setSelectedPoints
    };
  }
});
