













// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartRehabSideHopJumpProfile from '@/components/charts/rehab/ChartRehabSideHopJumpProfile.vue';
import ChartRehabSideHopJumpProfileSimplified from '@/components/charts/rehab/ChartRehabSideHopJumpProfileSimplified.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRehabContainerSideHopJumpProfile',
  components: {
    ChartContainer,
    ChartRehabSideHopJumpProfile,
    ChartRehabSideHopJumpProfileSimplified
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: false
    }
  },
  setup(properties) {
    const valid = computed(() => properties.displayMetrics.aggregates.jumping_flight_time);

    const advanced = computed(
      () =>
        properties.displayMetrics.aggregates.jumping_stance_time_left_stance &&
        properties.displayMetrics.aggregates.jumping_stance_time_right_stance &&
        properties.displayMetrics.aggregates.jumping_flight_time
    );

    return {
      valid,
      advanced
    };
  }
});
