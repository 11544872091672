































































































































































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, toRefs } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import DialogAnalysisOrthoticsGuide from '@/components/dialogs/DialogAnalysisOrthoticsGuide.vue';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
import i18n from '@/plugins/i18n';
import {
  getOrthoticsData,
  getInsoleImage,
  getInsoleDescriptions,
  getWedgesImages
} from '@/utils/orthotics.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsOrthotics',
  props: {
    analysis: {
      type: Object,
      required: true
    },
    scenario: {
      type: Object,
      required: true
    }
  },
  components: {
    DialogAnalysisOrthoticsGuide
  },
  setup(properties) {
    const busModule = usePodocoreModule('bus');

    const { analysis, scenario } = toRefs(properties);

    const analysisType = computed(() => scenario.value.key);

    const isWalking = computed(() => analysisType.value.startsWith('walking'));
    const isRunning = computed(() => analysisType.value.startsWith('running'));

    const orthoticsData = computed(() =>
      getOrthoticsData({
        isWalking: isWalking.value,
        isRunning: isRunning.value,
        analysis: analysis.value
      })
    );

    const soleImage = computed(() =>
      getInsoleImage({
        isWalking: isWalking.value,
        isRunning: isRunning.value,
        orthotics: orthoticsData.value
      })
    );

    const soleDescription = computed(() =>
      getInsoleDescriptions({
        isWalking: isWalking.value,
        isRunning: isRunning.value,
        orthotics: orthoticsData.value
      })
    );

    const wedges = computed(() =>
      getWedgesImages({
        isWalking: isWalking.value,
        isRunning: isRunning.value,
        orthotics: orthoticsData.value
      })
    );

    function openGuideDialog() {
      busModule.publish(
        busModule.events.openDialog({
          id: 'orthotics-guide'
        })
      );
    }

    return {
      orthoticsData,
      soleImage,
      soleDescription,
      wedges,
      openGuideDialog
    };
  }
});
