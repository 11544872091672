





















// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, reactive } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------//
import ChartWalkingContainerGroupStepsAngles from '@/components/charts/walking/containers/ChartWalkingContainerGroupStepsAngles.vue';
import ChartWalkingContainerFootProgressionAngleCircumduction from '@/components/charts/walking/containers/ChartWalkingContainerFootProgressionAngleCircumduction.vue';
import ChartWalkingContainerClearanceSteppage from '@/components/charts/walking/containers/ChartWalkingContainerClearanceSteppage.vue';
import ElementInterpretationManualBanner from '@/components/elements/ElementInterpretationManualBanner.vue';
// Import utils ------------------------------------------------------------------------------------
import { useInterpretationManual } from '@/utils/interpretation-manual.utils';
import { usePatient } from '@/utils/patient.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsWalkingAdvanced',
  components: {
    ChartWalkingContainerGroupStepsAngles,
    ChartWalkingContainerFootProgressionAngleCircumduction,
    ChartWalkingContainerClearanceSteppage,
    ElementInterpretationManualBanner
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    secondValues: {
      type: Object,
      required: false,
      default: null
    }
  },
  setup(properties) {
    const { data: patient } = usePatient();
    const { dontShowManualInterpretation } = useInterpretationManual();
    const charts = reactive({
      angles: {
        advanced: false,
        relative: false
      }
    });

    // Chart data
    const pronationSupinationAngles = computed(() => {
      return {
        // Angles
        walking_pronation_angle_foot_in: properties.aggregates.walking_pronation_angle_foot_in,
        walking_pronation_angle_FFI: properties.aggregates.walking_pronation_angle_FFI,
        walking_pronation_angle_FFO: properties.aggregates.walking_pronation_angle_FFO,
        walking_pronation_angle_foot_out: properties.aggregates.walking_pronation_angle_foot_out,
        // Phases
        walking_loading_time: properties.aggregates.walking_loading_time,
        walking_flatfoot_time: properties.aggregates.walking_flatfoot_time,
        walking_propulsion_time: properties.aggregates.walking_propulsion_time
      };
    });
    const footProgressionAngleCircumduction = computed(() => {
      return {
        // Foot progression angles
        walking_foot_progression_angle_flat_foot:
          properties.aggregates.walking_foot_progression_angle_flat_foot,
        // Circumduction
        walking_swing_width: properties.aggregates.walking_swing_width
      };
    });
    const clearanceSteppage = computed(() => {
      return {
        // Clearance
        walking_minimum_toe_clearance: properties.aggregates.walking_minimum_toe_clearance,
        // Circumduction
        walking_swing_width: properties.aggregates.walking_swing_width
      };
    });

    return {
      // Values
      patient,
      charts,
      // Charts
      pronationSupinationAngles,
      footProgressionAngleCircumduction,
      // Flags
      dontShowManualInterpretation
    };
  }
});
