"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoEntityCompletedProcessedVideoResolutionType = exports.VideoEntityOrientation = exports.VideoEntityStatus = void 0;
var VideoEntityStatus;
(function (VideoEntityStatus) {
    VideoEntityStatus["AwaitingUpload"] = "awaitingUpload";
    VideoEntityStatus["Processing"] = "processing";
    VideoEntityStatus["Completed"] = "completed";
    VideoEntityStatus["Errored"] = "errored";
})(VideoEntityStatus = exports.VideoEntityStatus || (exports.VideoEntityStatus = {}));
var VideoEntityOrientation;
(function (VideoEntityOrientation) {
    VideoEntityOrientation["Portrait"] = "portrait";
    VideoEntityOrientation["Landscape"] = "landscape";
})(VideoEntityOrientation = exports.VideoEntityOrientation || (exports.VideoEntityOrientation = {}));
var VideoEntityCompletedProcessedVideoResolutionType;
(function (VideoEntityCompletedProcessedVideoResolutionType) {
    VideoEntityCompletedProcessedVideoResolutionType["HD"] = "hd";
    VideoEntityCompletedProcessedVideoResolutionType["SD"] = "sd";
})(VideoEntityCompletedProcessedVideoResolutionType = exports.VideoEntityCompletedProcessedVideoResolutionType || (exports.VideoEntityCompletedProcessedVideoResolutionType = {}));
