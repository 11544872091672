











































































































// Import vendors --------------------------------------------------------------
import {
  defineComponent,
  ref,
  reactive,
  computed,
  watch,
  onBeforeUnmount,
  onMounted
} from '@vue/composition-api';
// Import components -----------------------------------------------------------
import DialogAnalysisCommentUpdate from '@/components/dialogs/DialogAnalysisCommentUpdate.vue';
// Import config ---------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import plugins --------------------------------------------------------------
import { usePodocoreModule, usePodocoreModuleService } from '@/plugins/podocore';
// Import utils ----------------------------------------------------------------
import { useRoute } from '@/utils/router.utils';
import { useAnalytics } from '@/utils/analytics.utils';
import { useSingleAnalysis } from '@/utils/single-analysis.utils';
// -----------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsComments',
  components: {
    DialogAnalysisCommentUpdate
  },
  setup() {
    const { singleAnalysis: analysis } = useSingleAnalysis();
    const { params } = useRoute();
    const { trackSuccess } = useAnalytics();

    const doctorService = usePodocoreModuleService('doctor');

    const doctor = computed(() => {
      return (doctorService.state.value as any).context.doctor;
    });

    const form = reactive({
      fields: {
        comment: {
          value: ''
        }
      }
    });

    const requestModule = usePodocoreModule('request');
    const { isPending, data, request, cancel } = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/analysis/${params.value.analysisCuid}/comments`
    );

    const comments = computed(() => data.value?.docs);

    const busModule = usePodocoreModule('bus');
    const commentCreateRequest = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/analysis/${params.value.analysisCuid}/comments`,
      {
        axios: {
          method: 'POST'
        }
      }
    );
    let commentDeleteRequest = ref<any>({});

    function saveNewComment() {
      commentCreateRequest.request({
        axios: {
          method: 'POST',
          data: {
            content: form.fields.comment.value
          }
        }
      });
      form.fields.comment.value = '';
    }

    function openEditComment(cuid: string) {
      busModule.publish(
        busModule.events.openDialog({
          id: 'analysis-comment-update',
          meta: { commentCuid: cuid, analysisCuid: analysis.value.cuid }
        })
      );
    }

    function deleteComment(cuid: string) {
      commentDeleteRequest.value = requestModule.useAuthenticatedRequest(
        `${apiConfig.default}/analysis/${analysis.value.cuid}/comments/${cuid}`,
        {
          axios: {
            method: 'DELETE'
          }
        }
      );
      commentDeleteRequest.value.request();
    }

    function breakLine(value: string) {
      return value.replace(/(\r\n|\n)/g, '<br/>');
    }

    const creation = computed(() => {
      return {
        pending: commentCreateRequest?.isPending?.value,
        success: !commentCreateRequest?.error?.value && !!commentCreateRequest?.data?.value,
        error: commentCreateRequest?.error?.value
      };
    });
    const deletion = computed(() => {
      return {
        pending: commentDeleteRequest.value?.isPending,
        success: !commentDeleteRequest.value?.error && !!commentDeleteRequest.value?.data,
        error: commentDeleteRequest.value?.error
      };
    });

    watch(creation, () => {
      if (creation.value.success) {
        request();
        trackSuccess('PatientAnalysis/General/comments');
      }
    });

    watch(deletion, () => {
      if (deletion.value.success) {
        request();
      }
    });

    onMounted(() => {
      request();
    });

    onBeforeUnmount(() => {
      cancel();
    });

    busModule.useEventSubscriber(busModule.events.analysisCommentPatched, () => {
      request();
    });

    return {
      // Values
      analysis,
      form,
      creation,
      deletion,
      comments,
      doctor,
      // Methods
      saveNewComment,
      openEditComment,
      deleteComment,
      breakLine,
      // Flags
      isPending
    };
  }
});
