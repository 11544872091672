











// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartRehabCMJJumpProfile from '@/components/charts/rehab/ChartRehabCMJJumpProfile.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRehabContainerCMJJumpProfile',
  components: {
    ChartContainer,
    ChartRehabCMJJumpProfile
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: false
    }
  }
});
