<template>
  <div class="w-100">
    <VRow>
      <VCol :cols="isLgAndLarger ? '3' : isMdAndLarger ? '2' : '4'">
        <div class="wrapper d-flex flex-column">
          <span class="title title--left">{{ $t('commons.standards.left').toUpperCase() }}</span>
          <ChartRehabTripleHopJumpProfileCard
            :title="$t('commons.standards.average')"
            :subtitle="$t('commons.standards.stance-time')"
            :data="data.leftContactTime"
          />
          <ChartRehabTripleHopJumpProfileCard
            :title="$t('commons.standards.average')"
            :subtitle="$t('commons.standards.swing-time')"
            :data="data.leftFlightTime"
          />
        </div>
      </VCol>
      <VCol
        :cols="isLgAndLarger ? '6' : isMdAndLarger ? '8' : '4'"
        class="wrapper d-flex align-center flex-column"
      >
        <span class="title">{{ $t('commons.standards.summary').toUpperCase() }}</span>
        <VChip color="black" small>
          <GlobalIcon icon="arrows-alt-v" color="white" />
          <span class="white--text font-weight-bold">{{ data.jumpHeight }}</span>
        </VChip>
        <VChip v-if="isAdvancedMode" color="#7E8391" small>
          <GlobalIcon icon="clock" color="white" />
          <span class="white--text font-weight-bold">{{ data.totalTime }}</span>
        </VChip>
        <ChartRehabTripleHopJumpProfile v-if="isMdAndLarger" v-bind="{ data, segments, isAdvancedMode }" />
      </VCol>
      <VCol :cols="isLgAndLarger ? '3' : isMdAndLarger ? '2' : '4'" class="d-flex justify-end">
        <div class="wrapper d-flex flex-column">
          <div class="title title--right d-flex justify-end">
            {{ $t('commons.standards.right').toUpperCase() }}
          </div>
          <ChartRehabTripleHopJumpProfileCard
            :title="$t('commons.standards.average')"
            :subtitle="$t('commons.standards.stance-time')"
            :data="data.rightContactTime"
          />
          <ChartRehabTripleHopJumpProfileCard
            :title="$t('commons.standards.average')"
            :subtitle="$t('commons.standards.swing-time')"
            :data="data.rightFlightTime"
          />
        </div>
      </VCol>
    </VRow>
    <VRow v-if="!isMdAndLarger" class="w-100">
      <VCol cols="12" class="d-flex justify-center">
        <ChartRehabTripleHopJumpProfile v-bind="{ data, segments, isAdvancedMode }" />
      </VCol>
    </VRow>
  </div>
</template>

<script>
// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useVuetifyBreakpoints } from '@/utils/vuetify-breakpoints.utils';
import { useRehab } from '@/utils/rehab.utils';
// Import components -------------------------------------------------------------------------------
import ChartRehabTripleHopJumpProfileCard from '@/components/charts/rehab/ChartRehabTripleHopJumpProfileCard.vue';
import ChartRehabTripleHopJumpProfile from '@/components/charts/rehab/ChartRehabTripleHopJumpProfile.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRehabGroupTripleHopJumpProfile',
  components: {
    ChartRehabTripleHopJumpProfileCard,
    ChartRehabTripleHopJumpProfile
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    segments: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    isAdvancedMode: {
      type: Boolean
    }
  },
  setup(properties) {
    const { isMdAndLarger, isLgAndLarger } = useVuetifyBreakpoints();
    const { getTripleHopJumpProfileData } = useRehab();

    const data = getTripleHopJumpProfileData(properties.aggregates, properties.segments);

    const valid = computed(
      () =>
        properties.displayMetrics.aggregates.jumping_flight_time &&
        properties.displayMetrics.segment.jumping_flight_time &&
        properties.displayMetrics.aggregates.jumping_stride_length &&
        properties.displayMetrics.segment.jumping_stride_length &&
        properties.displayMetrics.aggregates.jumping_stance_time &&
        properties.displayMetrics.segment.jumping_stance_time &&
        properties.displayMetrics.aggregates.jumping_distance &&
        properties.displayMetrics.segment.jumping_pronation_angle_foot_in
    );

    return {
      // Values
      data,
      valid,
      isMdAndLarger,
      isLgAndLarger
    };
  }
});
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;

  &--left {
    color: #6642cc;
  }

  &--right {
    color: #398ceb;
  }
}

.wrapper {
  gap: 1rem;
}
</style>
