













// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartWalkingPropulsionRatio from '@/components/charts/walking/ChartWalkingPropulsionRatio.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingContainerPropulsionRatio',
  components: {
    ChartWalkingPropulsionRatio,
    ChartContainer
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const valid = computed(() => properties.displayMetrics.aggregates.walking_propulsion_ratio);

    return {
      // Valid
      valid
    };
  }
});
