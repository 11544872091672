













// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartRehabContainerCMJJumpProfile from '@/components/charts/rehab/containers/ChartRehabContainerCMJJumpProfile.vue';
import ChartRehabContainerCMJGeneralParameters from '@/components/charts/rehab/containers/ChartRehabContainerCMJGeneralParameters.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsRehabCMJ',
  components: {
    ChartRehabContainerCMJJumpProfile,
    ChartRehabContainerCMJGeneralParameters
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: false
    }
  }
});
