













// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartRehabContainerSingleHopJumpProfile from '@/components/charts/rehab/containers/ChartRehabContainerSingleHopJumpProfile.vue';
import ChartRehabContainerSingleHopGeneralParameters from '@/components/charts/rehab/containers/ChartRehabContainerSingleHopGeneralParameters.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsRehabSingleHop',
  components: {
    ChartRehabContainerSingleHopJumpProfile,
    ChartRehabContainerSingleHopGeneralParameters
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: false
    }
  }
});
