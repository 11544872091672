















// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartWalkingStrideLength from '@/components/charts/walking/ChartWalkingStrideLength.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingContainerStrideLength',
  components: {
    ChartWalkingStrideLength,
    ChartContainer
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const valid = computed(() => properties.displayMetrics.aggregates.walking_stride_length);

    return {
      valid
    };
  }
});
