












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useInterpretationManual } from '@/utils/interpretation-manual.utils';
import { usePodocoreModuleService } from '@/plugins/podocore';
import { SupportedLang } from '@/utils/i18n.utils';
import { useAnalytics } from '@/utils/analytics.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ElementInterpretationManualBanner',
  props: {
    analysisType: {
      type: String,
      default: 'walking'
    }
  },
  setup(properties) {
    const { hide, downloadInterpretationManual } = useInterpretationManual();
    const { trackSuccess } = useAnalytics();

    const doctorService = usePodocoreModuleService('doctor');

    const currentLang = computed<SupportedLang>(() => {
      const lang = (doctorService?.state.value as any).context.doctor.config.lang;
      return lang === SupportedLang.En || lang === SupportedLang.Fr ? lang : SupportedLang.En;
    });

    function close() {
      hide();
    }

    function trackInterpretationManual() {
      trackSuccess('PatientAnalysis/Interpretation guide', {
        analysisType: properties.analysisType
      });
    }

    return {
      // Values,
      currentLang,
      // Methods
      downloadInterpretationManual,
      close,
      // Analytics
      trackInterpretationManual
    };
  }
});
