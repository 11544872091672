


















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartRehabContainerSideHopJumpProfile from '@/components/charts/rehab/containers/ChartRehabContainerSideHopJumpProfile.vue';
import ChartRehabContainerSideHopCadenceEvolution from '@/components/charts/rehab/containers/ChartRehabContainerSideHopCadenceEvolution.vue';
import ChartRehabContainerSideHopGeneralParameters from '@/components/charts/rehab/containers/ChartRehabContainerSideHopGeneralParameters.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsRehabSideHop',
  components: {
    ChartRehabContainerSideHopJumpProfile,
    ChartRehabContainerSideHopCadenceEvolution,
    ChartRehabContainerSideHopGeneralParameters
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    segments: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: false
    }
  }
});
