

































































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, nextTick, onMounted, Ref, ref, watch } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import AlertError from '@/components/alerts/AlertError.vue';
import AlertSuccess from '@/components/alerts/AlertSuccess.vue';
import ElementBgIcon from '@/components/elements/ElementBgIcon.vue';
// Import utils ------------------------------------------------------------------------------------
import { MediaType, useCDN } from '@/utils/cdn.utils';
import { ConditionsWalkingAidDevices } from '@/plugins/podocore/repositories/analysis.repository';
import { useAnalytics } from '@/utils/analytics.utils';
import { useSingleAnalysis } from '@/utils/single-analysis.utils';
// Import helpers ----------------------------------------------------------------------------------
import { enumToArrayAndTranslate } from '@/helpers/helped-functions.helper';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
import type { StepConditions } from '@/plugins/podocore/repositories/analysis.repository';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'DialogAnalysisConditionsWalkingAidsUpdate',
  components: {
    CompositeDialog,
    AlertError,
    AlertSuccess,
    ElementBgIcon
  },
  setup() {
    const dialogId: DialogId = 'analysis-conditions-walking-aids-update';
    const rDialog = ref<any>(null);

    const { setSingleAnalysisConditions } = useSingleAnalysis();

    const { cdn } = useCDN();
    const { trackSuccess, trackFailure } = useAnalytics();

    const requestModule = usePodocoreModule('request');
    const busModule = usePodocoreModule('bus');

    let analysisConditionsPatchRequest = ref<any>(null);

    const patchedWalkingAid: any = ref(null);

    const internalWalkingAidList: Ref<StepConditions['walkingAids']> = ref([]);
    const items = computed(() => {
      return enumToArrayAndTranslate(ConditionsWalkingAidDevices, {
        key: 'analysis.steps.conditions.walking_aids'
      });
    });
    const iconsKeyVersion = computed(() => {
      return [
        {
          id: 'simple-cane',
          key: '7PHUs5ZTXFdsKrErOMe0KETgoLroyqw1'
        },
        {
          id: 'english-cane',
          key: 'M.ulFIMkEpt1UKIYW8vZYmhAAcBNSHQE'
        },
        {
          id: 'english-cane-double',
          key: 'JmiiI2HY2bUnu4DF7HTM9A2GRtW3b36D'
        },
        {
          id: 'rollator',
          key: 'JneabHRx0k1XlPM7laah8dSjvLVbnGHv'
        },
        {
          id: 'walker',
          key: 'K.HdcYb_Y.4n1VX3N.Hl3Qr2uFOKFQzN'
        },
        {
          id: 'lift-and-orthopaedic-shoes',
          key: '.nkWmeslDrhh9XT5gGlzF2Rv9EJ9IhUj'
        },
        {
          id: 'other',
          key: 'Q63Jba_fR9ICPSms6L4WUrvWY82G70I8'
        }
      ];
    });

    function getIconFromKey(id: string) {
      return iconsKeyVersion.value.find((x: any) => x.id == id)!.key;
    }

    // TODO change img "english-cane-2" into "english-cane-double" into s3
    function getImg(key: string) {
      if (key === 'english-cane-double') return 'english-cane-2';
      return key;
    }

    function patchWalkingAids() {
      const data = {
        ...(rDialog.value.meta.conditions || { pains: [] }),
        walkingAidList: internalWalkingAidList.value
      };
      patchedWalkingAid.value = internalWalkingAidList.value;
      patchConditions(data);
    }

    function patchConditions(data: any) {
      analysisConditionsPatchRequest.value.request({
        axios: {
          method: 'PATCH',
          data
        }
      });
    }

    function closeDialog() {
      rDialog.value.close();
    }

    const isMounted = computed(() => analysisConditionsPatchRequest.value);

    const isDisabled = computed(
      () => !analysisConditionsPatchRequest.value || analysisConditionsPatchRequest.value.isPending
    );

    const isSuccess = computed(
      () => !analysisConditionsPatchRequest.value?.error && !!analysisConditionsPatchRequest.value?.data
    );
    watch(isSuccess, (value) => {
      if (value) {
        setSingleAnalysisConditions(analysisConditionsPatchRequest.value.data.conditions);
        busModule.publish(
          busModule.events.analysisConditionsPatched({
            analysisCuid: rDialog.value.meta.analysisCuid
          })
        );
        trackSuccess('PatientAnalysis/General/Summary/WalkingAid', patchedWalkingAid.value);
      }
    });

    const isError = computed(() => analysisConditionsPatchRequest.value?.error);
    watch(isError, (value) => {
      if (value)
        trackFailure('PatientAnalysis/General/Summary/WalkingAid', 'Patch conditions walking aid failed');
    });

    watch(isMounted, (value) => {
      if (value && rDialog.value.meta.conditions) {
        internalWalkingAidList.value = rDialog.value.meta.conditions.walkingAidList;
      }
    });

    onMounted(() => {
      watch(
        () => rDialog.value.displayContent,
        (value: boolean) => {
          if (value) {
            analysisConditionsPatchRequest.value = requestModule.useAuthenticatedRequest(
              `${apiConfig.default}/analysis/${rDialog.value.meta.analysisCuid}/conditions`,
              {
                axios: {
                  method: 'PATCH'
                }
              }
            );
          } else {
            analysisConditionsPatchRequest.value.cancel();
            nextTick(() => {
              analysisConditionsPatchRequest.value.clear();
            });
          }
        }
      );
    });

    return {
      cdn,
      rDialog,
      dialogId,
      analysisConditionsPatchRequest,
      internalWalkingAidList,
      items,
      // Methods
      getIconFromKey,
      getImg,
      patchWalkingAids,
      closeDialog,
      // Flags
      isMounted,
      isDisabled,
      isSuccess,
      //
      MediaType
    };
  }
});
