































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, toRefs, watch } from '@vue/composition-api';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsConditionsPainsFormLevel',
  props: {
    painLevel: {
      type: Number,
      default: null
    }
  },
  setup(properties, { emit }) {
    const { painLevel } = toRefs(properties);
    const painLevels: any = ref([
      {
        level: 0,
        color: '#2abf8f',
        name: 'no-pain',
        selected: false,
        hovered: false
      },
      {
        level: 1,
        color: '#5ABF75',
        name: 'mild-pain',
        selected: false,
        hovered: false
      },
      {
        level: 2,
        color: '#82BE5E',
        name: 'mild-pain',
        selected: false,
        hovered: false
      },
      {
        level: 3,
        color: '#ADBE48',
        name: 'mild-pain',
        selected: false,
        hovered: false
      },
      {
        level: 4,
        color: '#D5BD31',
        name: 'moderate-pain',
        selected: false,
        hovered: false
      },
      {
        level: 5,
        color: '#febd1b',
        name: 'moderate-pain',
        selected: false,
        hovered: false
      },
      {
        level: 6,
        color: '#FEAB1B',
        name: 'severe-pain',
        selected: false,
        hovered: false
      },
      {
        level: 7,
        color: '#FE981A',
        name: 'severe-pain',
        selected: false,
        hovered: false
      },
      {
        level: 8,
        color: '#FF861B',
        name: 'very-severe-pain',
        selected: false,
        hovered: false
      },
      {
        level: 9,
        color: '#FF731A',
        name: 'very-severe-pain',
        selected: false,
        hovered: false
      },
      {
        level: 10,
        color: '#ff5e1a',
        name: 'worst-pain-possible',
        selected: false,
        hovered: false
      }
    ]);

    watch(
      properties,
      () => {
        // Deselect all levels
        painLevels.value.map((x: any) => {
          x.selected = false;
        });
        // Select new level if edit mode
        if (painLevel.value != undefined) {
          const painIndex = painLevels.value.findIndex((x: any) => x.level === painLevel.value);
          if (painIndex >= 0) {
            painLevels.value[painIndex].selected = true;
          }
        }
      },
      { immediate: true, deep: true }
    );

    function selectPain(id: number) {
      const currentSelectedPain = painLevels.value.find((x: any) => x.selected);
      if (currentSelectedPain) currentSelectedPain.selected = false;
      painLevels.value[id].selected = true;
      emit('selectedPainLevel', painLevels.value[id].level);
    }

    return {
      // Values
      painLevels,
      // Methods,
      selectPain
    };
  }
});
