




















// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, ref } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartWalkingGaitLine from '@/components/charts/walking/ChartWalkingGaitLine.vue';
import ElementResultsSwitch from '@/components/elements/ElementResultsSwitch.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingContainerGaitLine',
  components: {
    ChartWalkingGaitLine,
    ElementResultsSwitch,
    ChartContainer
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const valid = computed(
      () =>
        properties.displayMetrics.aggregates.walking_gaitline_FFO &&
        properties.displayMetrics.aggregates.walking_gaitline_foot_in &&
        properties.displayMetrics.aggregates.walking_gaitline_foot_out &&
        properties.displayMetrics.aggregates.walking_gaitline_FFI &&
        properties.displayMetrics.aggregates.walking_loading_time &&
        properties.displayMetrics.aggregates.walking_propulsion_time &&
        properties.displayMetrics.aggregates.walking_flatfoot_time &&
        properties.displayMetrics.aggregates.walking_proportion_loading_time &&
        properties.displayMetrics.aggregates.walking_proportion_propulsion_time &&
        properties.displayMetrics.aggregates.walking_proportion_flatfoot_time
    );
    const analyticsData = {
      type: 'PatientAnalysis/General/gaitline/tooltip',
      analysisType: 'walking'
    };

    const absolute = ref(false);

    return {
      // Valid
      valid,
      // Analytics
      analyticsData,
      // Values
      absolute
    };
  }
});
