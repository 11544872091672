















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartRehabGroupGeneralParameters from '@/components/charts/rehab/groups/ChartRehabGroupGeneralParameters.vue';
import ElementResultsSwitch from '@/components/elements/ElementResultsSwitch.vue';
// Import utils ------------------------------------------------------------------------------------
import { useCDN } from '@/utils/cdn.utils';
import { useRehab } from '@/utils/rehab.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRehabContainerCMJGeneralParameters',
  components: {
    ChartContainer,
    ChartRehabGroupGeneralParameters,
    ElementResultsSwitch
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: false
    },
    displayMetrics: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const { cdn } = useCDN();
    const { getCMJGeneralParametersSimplifiedData, getCMJGeneralParametersAdvancedData } = useRehab();

    const isAdvancedMode = ref(false);

    const simplifiedModeCharts = getCMJGeneralParametersSimplifiedData(properties.aggregates);

    const advancedModeCharts = getCMJGeneralParametersAdvancedData(properties.aggregates);

    return {
      // Values
      isAdvancedMode,
      simplifiedModeCharts,
      advancedModeCharts,
      // Methods
      cdn
    };
  }
});
