


























// Import vendors --------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -----------------------------------------------------------
import PatientResultsGeneralInterpretations from '@/components/patient/results/interpretations/PatientResultsGeneralInterpretations.vue';
import PatientResultsComments from '@/components/patient/results/comments/PatientResultsComments.vue';
// -----------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsFooter',
  components: {
    PatientResultsGeneralInterpretations,
    PatientResultsComments
  },
  props: {
    analysis: {
      type: Object,
      required: true
    },
    degradedAnalysis: {
      type: Boolean
    },
    analysisType: {
      type: String
    }
  },
  setup(properties) {
    const columns = computed(() =>
      properties.degradedAnalysis ? 12 : properties.analysisType?.startsWith('walking') ? 6 : 12
    );

    return {
      columns
    };
  }
});
