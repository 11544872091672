























// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartWalkingGroupStepsAngles from '@/components/charts/walking/groups/ChartWalkingGroupStepsAngles.vue';
import ChartWalkingPronationSupinationAnglesAdvanced from '@/components/charts/walking/ChartWalkingPronationSupinationAnglesAdvanced.vue';
import ElementResultsSwitch from '@/components/elements/ElementResultsSwitch.vue';
import ElementResultsSwitchRelative from '@/components/elements/ElementResultsSwitchRelative.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingContainerGroupStepsAngles',
  components: {
    ChartWalkingGroupStepsAngles,
    ChartWalkingPronationSupinationAnglesAdvanced,
    ElementResultsSwitch,
    ElementResultsSwitchRelative,
    ChartContainer
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    globalView: {
      type: Boolean
    }
  },
  setup(properties) {
    const valid = computed(
      () =>
        properties.displayMetrics.aggregates.walking_pronation_angle_foot_in &&
        properties.displayMetrics.aggregates.walking_pronation_angle_foot_out
    );
    const advancedAngles = computed(
      () =>
        properties.displayMetrics.aggregates.walking_pronation_angle_FFO &&
        properties.displayMetrics.aggregates.walking_pronation_angle_foot_in &&
        properties.displayMetrics.aggregates.walking_pronation_angle_foot_out &&
        properties.displayMetrics.aggregates.walking_pronation_angle_FFI &&
        properties.displayMetrics.aggregates.walking_loading_time &&
        properties.displayMetrics.aggregates.walking_propulsion_time &&
        properties.displayMetrics.aggregates.walking_flatfoot_time
    );
    const relative = ref(false);
    const advanced = ref(false);

    onMounted(() => {
      if (properties.globalView) advanced.value = true;
    });

    return {
      // Valid
      valid,
      advancedAngles,
      // Values
      relative,
      advanced
    };
  }
});
