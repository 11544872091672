

































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, nextTick, onMounted, ref, watch } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import utils ----------------------------------------------------------------------------------
import { useAnalytics } from '@/utils/analytics.utils';
import { useSingleAnalysis } from '@/utils/single-analysis.utils';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import AlertError from '@/components/alerts/AlertError.vue';
import AlertSuccess from '@/components/alerts/AlertSuccess.vue';
import PatientResultsConditionsPainsForm from '@/components/patient/results/conditions/PatientResultsConditionsPainsForm.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'DialogAnalysisConditionsPainsUpdate',
  components: {
    CompositeDialog,
    AlertError,
    AlertSuccess,
    PatientResultsConditionsPainsForm
  },
  setup() {
    const dialogId: DialogId = 'analysis-conditions-pains-update';
    const rDialog = ref<any>(null);

    const { setSingleAnalysisConditions } = useSingleAnalysis();

    const { trackSuccess, trackFailure } = useAnalytics();

    const requestModule = usePodocoreModule('request');
    const busModule = usePodocoreModule('bus');

    let analysisConditionsPatchRequest = ref<any>(null);

    const patchedPain: any = ref(null);
    const selectedLevel: any = ref(null);

    const isMounted = computed(() => analysisConditionsPatchRequest.value);

    const isDisabled = computed(
      () => !analysisConditionsPatchRequest.value || analysisConditionsPatchRequest.value.isPending
    );

    const isSuccess = computed(
      () => !analysisConditionsPatchRequest.value?.error && !!analysisConditionsPatchRequest.value?.data
    );
    watch(isSuccess, (value) => {
      if (value) {
        setSingleAnalysisConditions(analysisConditionsPatchRequest.value.data.conditions);
        busModule.publish(
          busModule.events.analysisConditionsPatched({
            analysisCuid: rDialog.value.meta.analysisCuid
          })
        );
        trackSuccess('PatientAnalysis/General/Summary/Pains', patchedPain.value);
      }
    });

    const isError = computed(() => analysisConditionsPatchRequest.value?.error);
    watch(isError, (value) => {
      if (value) trackFailure('PatientAnalysis/General/Summary/Pains', 'Patch conditions pains failed');
    });

    function patchPainList(data: any) {
      patchedPain.value = data.patchedPain;
      patchConditions(data.conditions);
    }

    function patchConditions(data: any) {
      analysisConditionsPatchRequest.value.request({
        axios: {
          method: 'PATCH',
          data
        }
      });
    }

    function submitForm() {
      rDialog.value.$children[0].$children[0].$children[0].$children[1].submit();
    }

    function setPainLevel(painLevel: number) {
      selectedLevel.value = painLevel;
    }

    function closeDialog() {
      rDialog.value.close();
    }

    onMounted(() => {
      watch(
        () => rDialog.value.displayContent,
        (value: boolean) => {
          if (value) {
            analysisConditionsPatchRequest.value = requestModule.useAuthenticatedRequest(
              `${apiConfig.default}/analysis/${rDialog.value.meta.analysisCuid}/conditions`,
              {
                axios: {
                  method: 'PATCH'
                }
              }
            );
          } else {
            analysisConditionsPatchRequest.value.cancel();
            nextTick(() => {
              analysisConditionsPatchRequest.value.clear();
            });
          }
        }
      );
    });

    return {
      rDialog,
      dialogId,
      analysisConditionsPatchRequest,
      selectedLevel,
      // Methods
      patchPainList,
      submitForm,
      closeDialog,
      setPainLevel,
      // Flags
      isMounted,
      isDisabled,
      isSuccess
    };
  }
});
