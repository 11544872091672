











































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ElementResultsTooltip from '@/components/elements/ElementResultsTooltip.vue';
import ChartStandardScoring from '@/components/charts/standard/ChartStandardScoring.vue';
import ChartStandardScoringSections from '@/components/charts/standard/ChartStandardScoringSections.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsScoring',
  components: {
    ElementResultsTooltip,
    ChartStandardScoring,
    ChartStandardScoringSections
  },
  props: {
    analysis: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const SQIParameters: any = {
      walking_stride_length: {
        name: 'commons.standards.stride-length',
        desc: 'commons.standards.stride-length.desc',
        indicator: {
          left: properties.analysis.metrics.aggregates.walking_stride_length.left_foot.indicator,
          right: properties.analysis.metrics.aggregates.walking_stride_length.right_foot.indicator
        }
      },
      walking_swing_width: {
        name: 'commons.standards.circumduction',
        desc: 'commons.standards.circumduction.desc',
        indicator: {
          left: properties.analysis.metrics.aggregates.walking_swing_width.left_foot.indicator,
          right: properties.analysis.metrics.aggregates.walking_swing_width.right_foot.indicator
        }
      },
      walking_foot_progression_angle_flat_foot: {
        name: 'commons.standards.foot-progression-angle',
        desc: 'commons.standards.foot-progression-angle.desc',
        indicator: {
          left: properties.analysis.metrics.aggregates.walking_foot_progression_angle_flat_foot.left_foot
            .indicator,
          right:
            properties.analysis.metrics.aggregates.walking_foot_progression_angle_flat_foot.right_foot
              .indicator
        }
      },
      walking_proportion_swing_time: {
        name: 'commons.standards.swing-time',
        desc: 'commons.standards.swing-time.desc',
        indicator: {
          left: properties.analysis.metrics.aggregates.walking_proportion_swing_time.left_foot.indicator,
          right: properties.analysis.metrics.aggregates.walking_proportion_swing_time.right_foot.indicator
        }
      },
      walking_proportion_stance_time: {
        name: 'commons.standards.stance-time',
        desc: 'commons.standards.stance-time.desc',
        indicator: {
          left: properties.analysis.metrics.aggregates.walking_proportion_stance_time.left_foot.indicator,
          right: properties.analysis.metrics.aggregates.walking_proportion_stance_time.right_foot.indicator
        }
      },
      walking_pronation_angle_foot_in: {
        name: 'commons.standards.heel-strike',
        desc: 'commons.standards.heel-strike.desc',
        indicator: {
          left: properties.analysis.metrics.aggregates.walking_pronation_angle_foot_in.left_foot.indicator,
          right: properties.analysis.metrics.aggregates.walking_pronation_angle_foot_in.right_foot.indicator
        }
      },
      walking_pronation_angle_FFI: {
        name: 'commons.standards.flat-foot',
        desc: 'commons.standards.flat-foot.desc',
        indicator: {
          left: properties.analysis.metrics.aggregates.walking_pronation_angle_FFI.left_foot.indicator,
          right: properties.analysis.metrics.aggregates.walking_pronation_angle_FFI.right_foot.indicator
        }
      },
      walking_pronation_angle_FFO: {
        name: 'commons.standards.heel-off',
        desc: 'commons.standards.heel-off.desc',
        indicator: {
          left: properties.analysis.metrics.aggregates.walking_pronation_angle_FFO.left_foot.indicator,
          right: properties.analysis.metrics.aggregates.walking_pronation_angle_FFO.right_foot.indicator
        }
      },
      walking_pronation_angle_foot_out: {
        name: 'commons.standards.toes-off',
        desc: 'commons.standards.toes-off.desc',
        indicator: {
          left: properties.analysis.metrics.aggregates.walking_pronation_angle_foot_out.left_foot.indicator,
          right: properties.analysis.metrics.aggregates.walking_pronation_angle_foot_out.right_foot.indicator
        }
      },
      walking_relative_pronation_angle_foot_in: {
        name: 'commons.standards.heel-strike',
        desc: 'commons.standards.heel-strike-relative.desc',
        indicator: {
          left: properties.analysis.metrics.aggregates.walking_relative_pronation_angle_foot_in.left_foot
            .indicator,
          right:
            properties.analysis.metrics.aggregates.walking_relative_pronation_angle_foot_in.right_foot
              .indicator
        }
      },
      walking_relative_pronation_angle_FFI: {
        name: 'commons.standards.falt-foot',
        desc: 'commons.standards.falt-foot-relative.desc',
        indicator: {
          left: properties.analysis.metrics.aggregates.walking_relative_pronation_angle_FFI.left_foot
            .indicator,
          right:
            properties.analysis.metrics.aggregates.walking_relative_pronation_angle_FFI.right_foot.indicator
        }
      },
      walking_relative_pronation_angle_FFO: {
        name: 'commons.standards.heel-off',
        desc: 'commons.standards.heel-off-relative.desc',
        indicator: {
          left: properties.analysis.metrics.aggregates.walking_relative_pronation_angle_FFO.left_foot
            .indicator,
          right:
            properties.analysis.metrics.aggregates.walking_relative_pronation_angle_FFO.right_foot.indicator
        }
      },
      walking_relative_pronation_angle_foot_out: {
        name: 'commons.standards.toes-off',
        desc: 'commons.standards.toes-off-relative.desc',
        indicator: {
          left: properties.analysis.metrics.aggregates.walking_relative_pronation_angle_foot_out.left_foot
            .indicator,
          right:
            properties.analysis.metrics.aggregates.walking_relative_pronation_angle_foot_out.right_foot
              .indicator
        }
      },
      walking_minimum_toe_clearance: {
        name: 'commons.standards.clearance',
        desc: 'commons.standards.clearance.desc',
        indicator: {
          left: properties.analysis.metrics.aggregates.walking_minimum_toe_clearance.left_foot.indicator,
          right: properties.analysis.metrics.aggregates.walking_minimum_toe_clearance.right_foot.indicator
        }
      },
      walking_plantar_flexion_angle_foot_in: {
        name: 'commons.standards.heel-strike',
        desc: 'commons.standards.heel-strike-plantar-flexion.desc',
        indicator: {
          left: properties.analysis.metrics.aggregates.walking_plantar_flexion_angle_foot_in.left_foot
            .indicator,
          right:
            properties.analysis.metrics.aggregates.walking_plantar_flexion_angle_foot_in.right_foot.indicator
        }
      },
      walking_plantar_flexion_angle_foot_out: {
        name: 'commons.standards.toes-off',
        desc: 'ommons.standards.toes-off-plantar-flexion.desc',
        indicator: {
          left: properties.analysis.metrics.aggregates.walking_plantar_flexion_angle_foot_out.left_foot
            .indicator,
          right:
            properties.analysis.metrics.aggregates.walking_plantar_flexion_angle_foot_out.right_foot.indicator
        }
      },
      walking_proportion_loading_time: {
        name: 'commons.standards.taligrade',
        desc: 'commons.standards.taligrade.desc',
        indicator: {
          left: properties.analysis.metrics.aggregates.walking_proportion_loading_time.left_foot.indicator,
          right: properties.analysis.metrics.aggregates.walking_proportion_loading_time.right_foot.indicator
        }
      },
      walking_proportion_propulsion_time: {
        name: 'commons.standards.plantigrade',
        desc: 'commons.standards.plantigrade.desc',
        indicator: {
          left: properties.analysis.metrics.aggregates.walking_proportion_propulsion_time.left_foot.indicator,
          right:
            properties.analysis.metrics.aggregates.walking_proportion_propulsion_time.right_foot.indicator
        }
      },
      walking_proportion_flatfoot_time: {
        name: 'commons.standards.digitigrade',
        desc: 'commons.standards.digitigrade.desc',
        indicator: {
          left: properties.analysis.metrics.aggregates.walking_proportion_flatfoot_time.left_foot.indicator,
          right: properties.analysis.metrics.aggregates.walking_proportion_flatfoot_time.right_foot.indicator
        }
      },
      walking_proportion_double_support_time: {
        name: 'commons.standards.double-contact',
        desc: 'commons.standards.double-contact.desc',
        indicator: {
          global:
            properties.analysis.metrics.aggregates.walking_proportion_double_support_time.global.indicator
        }
      },
      walking_symmetry: {
        name: 'commons.standards.symmetry',
        desc: 'commons.standards.symmetry.desc',
        indicator: {
          global: properties.analysis.metrics.aggregates.walking_symmetry.global.indicator
        }
      }
    };
    const GVIParameters = computed(() => {
      return [
        {
          id: 'walking_step_length_variability_score',
          name: 'commons.standards.step-length',
          desc: 'commons.standards.step-length.desc',
          indicator: {
            left: properties.analysis.metrics.aggregates.walking_step_length_variability_score.left_foot
              .indicator,
            right:
              properties.analysis.metrics.aggregates.walking_step_length_variability_score.right_foot
                .indicator
          }
        },
        {
          id: 'walking_step_time_variability_score',
          name: 'commons.standards.step-time',
          desc: 'commons.standards.step-time.desc',
          indicator: {
            left: properties.analysis.metrics.aggregates.walking_step_time_variability_score.left_foot
              .indicator,
            right:
              properties.analysis.metrics.aggregates.walking_step_time_variability_score.right_foot.indicator
          }
        },
        {
          id: 'walking_stance_time_variability_score',
          name: 'commons.standards.stance-time',
          desc: 'commons.standards.stance-time.desc',
          indicator: {
            left: properties.analysis.metrics.aggregates.walking_stance_time_variability_score.left_foot
              .indicator,
            right:
              properties.analysis.metrics.aggregates.walking_stance_time_variability_score.right_foot
                .indicator
          }
        },
        {
          id: 'walking_single_support_time_variability_score',
          name: 'commons.standards.single-support-time',
          desc: 'commons.standards.single-support-time.desc',
          indicator: {
            left: properties.analysis.metrics.aggregates.walking_single_support_time_variability_score
              .left_foot.indicator,
            right:
              properties.analysis.metrics.aggregates.walking_single_support_time_variability_score.right_foot
                .indicator
          }
        },
        {
          id: 'walking_stride_velocity_variability_score',
          name: 'commons.standards.stride-velocity-variability',
          desc: 'commons.standards.stride-velocity-variability.desc',
          indicator: {
            left: properties.analysis.metrics.aggregates.walking_stride_velocity_variability_score.left_foot
              .indicator,
            right:
              properties.analysis.metrics.aggregates.walking_stride_velocity_variability_score.right_foot
                .indicator
          }
        }
      ];
    });

    return {
      SQIParameters,
      GVIParameters
    };
  }
});
