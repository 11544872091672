














// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ElementBgIcon',
  props: {
    color: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      default: 30
    },
    ratio: {
      type: Number,
      default: 24
    },
    width: {
      type: Number,
      default: 56
    },
    height: {
      type: Number,
      default: 56
    },
    backgroundVar: {
      type: String,
      default: null
    },
    backgroundColor: {
      type: String,
      default: null
    },
    inverted: {
      type: Boolean
    }
  },
  setup(properties) {
    const _backgroundVariable = computed(() => {
      const c = (properties.backgroundVar || properties.color).split(' ');
      const s = new Option().style;
      s.color = c[0];
      if (s.color === c[0] && c.length === 1) {
        return c[0];
      } else {
        return `var(--v-${c[0]}-${c[1] ? c[1].replace('-', '') : 'lighten2'})`;
      }
    });

    const background = computed(() => {
      if (properties.backgroundColor) return properties.backgroundColor;
      return _backgroundVariable;
    });

    const isNotLibraryIcon = computed(() => {
      return /^http/.test(properties.icon);
    });

    return {
      background,
      isNotLibraryIcon
    };
  }
});
