
































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import PatientResultVideos from '@/components/patient/results/PatientResultVideos.vue';
import ChartWalkingContainerGroupBanner from '@/components/charts/walking/containers/ChartWalkingContainerGroupBanner.vue';
import ChartWalkingContainerWalkProfileDegraded from '@/components/charts/walking/containers/ChartWalkingContainerWalkProfileDegraded.vue';
import ChartWalkingContainerFootProgressionAngleCircumduction from '@/components/charts/walking/containers/ChartWalkingContainerFootProgressionAngleCircumduction.vue';
// Import plugins ----------------------------------------------------------------------------------
import { usePatient } from '@/utils/patient.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsWalkingDegraded',
  components: {
    ChartWalkingContainerGroupBanner,
    ChartWalkingContainerWalkProfileDegraded,
    ChartWalkingContainerFootProgressionAngleCircumduction,
    PatientResultVideos
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    analysis: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { data: patient } = usePatient();

    return {
      // Values
      patient
    };
  }
});
