



















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, reactive } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'DialogAnalysisOrthoticsGuide',
  components: { CompositeDialog },
  setup() {
    const dialogId: DialogId = 'orthotics-guide';
    const rDialog = ref<any>(null);

    function closeDialog() {
      rDialog.value.close();
    }

    const steps = ref([
      {
        title: 'First step',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: ''
      },
      {
        title: 'Second step',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: ''
      },
      {
        title: 'Third step',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        img: ''
      }
    ]);

    return {
      rDialog,
      dialogId,
      steps,
      // Methods
      closeDialog
    };
  }
});
