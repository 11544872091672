
























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ElementBgIcon from '@/components/elements/ElementBgIcon.vue';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import types ------------------------------------------------------------------------------------
import type { AnalysisEntityCompleted } from '@digitsole/blackburn-entities/dist/entities/analysis/analysis-completed.entity';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsErroneous',
  props: {
    values: {
      type: Object,
      required: true
    }
  },
  components: {
    ElementBgIcon
  },
  setup(properties: { values: AnalysisEntityCompleted }) {
    const busModule = usePodocoreModule('bus');

    function openDelete() {
      busModule.publish(
        busModule.events.openDialog({
          id: 'analysis-delete',
          meta: { analysisCuid: properties.values.cuid }
        })
      );
    }

    return {
      openDelete
    };
  }
});
