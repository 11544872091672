













// Import vendors -----ChartWalkingClearanceSteppage-----------------------------------------------------------------------------
import { computed, defineComponent, ref } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartWalkingClearanceSteppage from '@/components/charts/walking/ChartWalkingClearanceSteppage.vue';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingContainerClearanceSteppage',
  components: {
    ChartContainer,
    ChartWalkingClearanceSteppage
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const valid = computed(() => properties.displayMetrics.aggregates.walking_plantar_flexion_angle_foot_in);
    const simplified = computed(
      () =>
        properties.displayMetrics.aggregates.walking_plantar_flexion_angle_foot_in &&
        !properties.displayMetrics.aggregates.walking_plantar_flexion_angle_foot_out
    );

    const normsType = computed(() => {
      return {
        base: [
          properties.norms.walking_minimum_toe_clearance.bounds.neutral.min,
          properties.norms.walking_minimum_toe_clearance.bounds.neutral.max
        ]
      };
    });

    const clearanceUnit = computed(() => getUnit(EValueTypeUnit.Clearance));

    return {
      // Valid
      valid,
      simplified,
      // Values
      clearanceUnit,
      normsType
    };
  }
});
