














// Import vendors --------------------------------------------------------------
import { defineComponent, ref, watchEffect, Ref } from '@vue/composition-api';
// Import plugins --------------------------------------------------------------
import i18n from '@/plugins/i18n';
// -----------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsGeneralInterpretations',
  props: {
    recommendations: {
      type: Object
    },
    interpretations: {
      type: Object
    }
  },
  setup(properties) {
    const generalParameters: Ref<string | null> = ref(null);
    const angleParameters: Ref<string | null> = ref(null);

    watchEffect(() => {
      getInterpretations();
    });

    function getInterpretations() {
      generalParameters.value = getInterpretationsText(properties.recommendations?.general_parameters);
      if (properties.interpretations)
        angleParameters.value = getInterpretationsText(properties.interpretations.roll_out);
    }

    function getInterpretationsText(parameter: any) {
      const global = parameter?.global || null;
      const left = parameter?.left_foot || null;
      const right = parameter?.right_foot || null;

      let text = '';
      let globalText = null;

      if (left && right && left === right) {
        text = `${getTranslate(checkRollOutText(parameter.left_foot), 'symmetry')}`;
      }

      if (left && right && left !== right) {
        text = `${getTranslate(checkRollOutText(parameter.left_foot), 'left')} - ${getTranslate(
          parameter.right_foot,
          'right'
        )}`;
      }

      if (left && !right) {
        text = `${getTranslate(checkRollOutText(parameter.left_foot), 'left')}`;
      }

      if (!left && right) {
        text = `${getTranslate(checkRollOutText(parameter.right_foot), 'right')}`;
      }

      if (global) {
        globalText = `${getTranslate(parameter.global, 'globals')}`;
      }

      if (!left && !right && !global) {
        text = '';
      }

      return `${globalText ? globalText + '\n' + text : text}`;
    }

    function getTranslate(code: string, type: string) {
      return i18n.t(`interpretations.${type}.${code}`);
    }

    // Check because of biomechanical forgot
    function checkRollOutText(text: string) {
      if (text === 'ROLL_OUT_4' || text === 'ROLL_OUT_5') {
        return text.slice(0, 9) + 'TEXT_' + text.slice(9, 10);
      }

      return text;
    }

    function breakLine(value: string) {
      return value.replace(/(\r\n|\n)/g, '<br/>');
    }

    return {
      // Values
      generalParameters,
      angleParameters,
      // Methods
      breakLine
    };
  }
});
