


































































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import PatientResultSummary from '@/components/patient/results/PatientResultSummary.vue';
import ChartWalkingContainerGaitLine from '@/components/charts/walking/containers/ChartWalkingContainerGaitLine.vue';
import ChartWalkingContainerWalkProfile from '@/components/charts/walking/containers/ChartWalkingContainerWalkProfile.vue';
import ChartWalkingContainerPropulsionRatio from '@/components/charts/walking/containers/ChartWalkingContainerPropulsionRatio.vue';
import ChartWalkingContainerGroupStepsAngles from '@/components/charts/walking/containers/ChartWalkingContainerGroupStepsAngles.vue';
import ChartWalkingContainerFootProgressionAngle from '@/components/charts/walking/containers/ChartWalkingContainerFootProgressionAngle.vue';
import ChartWalkingContainerClearanceSteppage from '@/components/charts/walking/containers/ChartWalkingContainerClearanceSteppage.vue';
import ChartWalkingContainerSymmetry from '@/components/charts/walking/containers/ChartWalkingContainerSymmetry.vue';
import ChartWalkingContainerCadence from '@/components/charts/walking/containers/ChartWalkingContainerCadence.vue';
import ChartWalkingContainerSpeed from '@/components/charts/walking/containers/ChartWalkingContainerSpeed.vue';
import ChartWalkingContainerDistance from '@/components/charts/walking/containers/ChartWalkingContainerDistance.vue';
import ElementInterpretationManualBanner from '@/components/elements/ElementInterpretationManualBanner.vue';
import ChartWalkingContainerStrideLength from '@/components/charts/walking/containers/ChartWalkingContainerStrideLength.vue';
import ChartWalkingContainerStanceTime from '@/components/charts/walking/containers/ChartWalkingContainerStanceTime.vue';
import ChartWalkingContainerCircumduction from '@/components/charts/walking/containers/ChartWalkingContainerCircumduction.vue';
import ChartWalkingContainerSwingTime from '@/components/charts/walking/containers/ChartWalkingContainerSwingTime.vue';
import ChartStandardContainerSliderAdvanced from '@/components/charts/standard/containers/ChartStandardContainerSliderAdvanced.vue';
// Import utils ------------------------------------------------------------------------------------
import { useInterpretationManual } from '@/utils/interpretation-manual.utils';
import { usePatient } from '@/utils/patient.utils';
import ChartWalkingContainerClearance from '@/components/charts/walking/containers/ChartWalkingContainerClearance.vue';
import i18n from '@/plugins/i18n';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsWalkingPathology',
  components: {
    PatientResultSummary,
    ChartWalkingContainerSymmetry,
    ChartWalkingContainerCadence,
    ChartWalkingContainerSpeed,
    ChartWalkingContainerDistance,
    ElementInterpretationManualBanner
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    pathology: {
      type: String,
      required: true
    },
    norms: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const { data: patient } = usePatient();
    const { dontShowManualInterpretation } = useInterpretationManual();

    const advanced = ref(false);

    const chartsPathology: any = reactive({
      neurological: [
        {
          chart: ChartWalkingContainerStrideLength,
          cols: 12,
          md: 6,
          lg: 6,
          xl: 6
        },
        {
          chart: ChartWalkingContainerStanceTime,
          cols: 12,
          md: 6,
          lg: 6,
          xl: 6
        },
        {
          chart: ChartWalkingContainerClearanceSteppage,
          cols: 12,
          md: 6,
          lg: 6,
          xl: 6
        },
        {
          chart: ChartWalkingContainerPropulsionRatio,
          cols: 12,
          md: 6,
          lg: 6,
          xl: 6
        },
        {
          chart: ChartWalkingContainerCircumduction,
          cols: 12,
          md: 6,
          lg: 6,
          xl: 6
        },
        {
          advanced: true,
          chart: ChartWalkingContainerGaitLine,
          cols: 12,
          md: 8,
          lg: 8,
          xl: 8
        },
        {
          advanced: true,
          chart: ChartWalkingContainerFootProgressionAngle,
          props: { simple: true },
          cols: 12,
          md: 4,
          lg: 4,
          xl: 4
        }
      ],
      traumatology: [
        {
          chart: ChartWalkingContainerWalkProfile,
          props: { norms: properties.norms },
          cols: 12,
          md: 12,
          lg: 12,
          xl: 12
        },
        {
          chart: ChartWalkingContainerGroupStepsAngles,
          props: { globalView: true },
          cols: 12,
          md: 12,
          lg: 12,
          xl: 12
        },
        {
          chart: ChartWalkingContainerGaitLine,
          cols: 12,
          md: 8,
          lg: 8,
          xl: 8
        },
        {
          chart: ChartWalkingContainerFootProgressionAngle,
          props: { simple: true },
          cols: 12,
          md: 4,
          lg: 4,
          xl: 4
        },
        {
          advanced: true,
          chart: ChartWalkingContainerCircumduction,
          cols: 12,
          md: 6,
          lg: 6,
          xl: 6
        },
        {
          advanced: true,
          chart: ChartWalkingContainerStanceTime,
          cols: 12,
          md: 6,
          lg: 6,
          xl: 6
        },
        {
          advanced: true,
          chart: ChartWalkingContainerPropulsionRatio,
          cols: 12,
          md: 6,
          lg: 6,
          xl: 6
        }
      ],
      're-athleticism': [
        {
          chart: ChartWalkingContainerStanceTime,
          cols: 12,
          md: 6,
          lg: 6,
          xl: 6
        },
        {
          chart: ChartWalkingContainerStrideLength,
          cols: 12,
          md: 6,
          lg: 6,
          xl: 6
        },
        {
          chart: ChartWalkingContainerPropulsionRatio,
          cols: 12,
          md: 6,
          lg: 6,
          xl: 6
        },
        {
          chart: ChartWalkingContainerClearanceSteppage,
          cols: 12,
          md: 6,
          lg: 6,
          xl: 6
        },
        {
          chart: ChartWalkingContainerGroupStepsAngles,
          props: { globalView: true },
          cols: 12,
          md: 12,
          lg: 12,
          xl: 12
        },
        {
          chart: ChartWalkingContainerFootProgressionAngle,
          cols: 12,
          md: 4,
          lg: 4,
          xl: 4
        },
        {
          // advanced: true,
          chart: ChartWalkingContainerGaitLine,
          cols: 12,
          md: 8,
          lg: 8,
          xl: 8
        },
        {
          advanced: true,
          split: true,
          cols: 12,
          md: 6,
          lg: 6,
          xl: 6,
          charts: [
            {
              chart: ChartWalkingContainerCircumduction,
              props: { vertical: true }
            },
            {
              chart: ChartStandardContainerSliderAdvanced,
              props: {
                title: i18n.t('commons.standards.stride-duration'),
                aggregates: properties.aggregates.walking_stride_time,
                norms: properties.norms.walking_stride_time.bounds,
                unit: 'ms'
              }
            }
          ]
        },
        {
          chart: ChartWalkingContainerSwingTime,
          advanced: true,
          cols: 12,
          md: 6,
          lg: 6,
          xl: 6
        },
        {
          chart: ChartWalkingContainerClearance,
          advanced: true,
          cols: 12,
          md: 6,
          lg: 6,
          xl: 6
        }
      ]
    });

    const chartsByPathology = computed(() =>
      chartsPathology[properties.pathology].filter((x: any) => !x.advanced)
    );
    const advancedChartsByPathology = computed(() =>
      chartsPathology[properties.pathology].filter((x: any) => x.advanced)
    );

    watch(properties, () => {
      advanced.value = false;
    });

    return {
      // Values
      patient,
      advanced,
      chartsByPathology,
      advancedChartsByPathology,
      // Flags
      dontShowManualInterpretation
    };
  }
});
