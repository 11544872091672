var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isPending)?_c('VProgressCircular',{attrs:{"color":"primary","width":"2","size":"16","indeterminate":""}}):_vm._e(),_vm._l((_vm.comments),function(comment){return _c('VHover',{key:comment.cuid,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"comment-list",attrs:{"border":""}},[_c('div',{staticClass:"comment-list__header"},[_c('div',[(comment._doctor)?_c('div',[_vm._v(" "+_vm._s(comment._doctor.profile.firstName)+" "+_vm._s(comment._doctor.profile.lastName)+" ")]):_vm._e(),_c('div',[_vm._v(_vm._s(_vm.$moment(comment.updatedAt).calendar()))])]),(comment._doctor.cuid === _vm.doctor.cuid)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}]},[_c('VTooltip',{attrs:{"color":"primary","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('VBtn',_vm._g({attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.openEditComment(comment.cuid)}}},on),[_c('GlobalIcon',{attrs:{"icon":"pen","size":"12"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('commons.actions.edit')))])]),_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('VBtn',_vm._g({attrs:{"loading":_vm.deletion.pending,"depressed":"","small":"","icon":""},on:{"click":function($event){return _vm.deleteComment(comment.cuid)}}},on),[_c('GlobalIcon',{attrs:{"icon":"trash-alt","color":"secondary","size":"12"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('commons.actions.delete')))])])],1):_vm._e()]),_c('span',{staticClass:"comment-list__content",domProps:{"innerHTML":_vm._s(_vm.breakLine(comment.content))}})])]}}],null,true)})}),(_vm.creation.pending)?_c('VProgressCircular',{attrs:{"color":"primary","width":"2","size":"16","indeterminate":""}}):_vm._e(),(_vm.creation.error)?_c('VAlert',{attrs:{"type":"error","text":""}},[_vm._v(" "+_vm._s(_vm.creation.error.message)+" ")]):_vm._e(),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"comment-form"},[_c('VForm',{ref:"form",staticClass:"write-comment",on:{"submit":function($event){$event.preventDefault();return _vm.saveNewComment()}}},[_c('ValidationProvider',{attrs:{"name":"comment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VTextarea',{ref:"comment",attrs:{"placeholder":_vm.$t('commons.sentences.write-comment-here') + '...',"disabled":_vm.creation.pending,"error-messages":errors,"hide-details":!errors || !errors.length,"background-color":"transparent","rows":"3","auto-grow":"","dense":"","solo":"","flat":""},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if(!$event.ctrlKey){ return null; }return _vm.saveNewComment()}},model:{value:(_vm.form.fields.comment.value),callback:function ($$v) {_vm.$set(_vm.form.fields.comment, "value", $$v)},expression:"form.fields.comment.value"}})]}}],null,true)})],1),_c('VBtn',{attrs:{"loading":_vm.creation.pending,"disabled":invalid || !_vm.form.fields.comment.value,"color":"primary","depressed":"","rounded":""},on:{"click":function($event){return _vm.saveNewComment()}}},[_c('span',[_vm._v(_vm._s(_vm.$t('commons.actions.save-comment')))])])],1)]}}])}),(_vm.deletion.error)?_c('VAlert',{attrs:{"type":"error","text":""}},[_vm._v(" "+_vm._s(_vm.deletion.error.message)+" ")]):_vm._e(),_c('VSnackbar',{attrs:{"timeout":"3000","color":"success","top":""},model:{value:(_vm.deletion.success),callback:function ($$v) {_vm.$set(_vm.deletion, "success", $$v)},expression:"deletion.success"}},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$tc('commons.sentences.comment-deleted')))]),_c('GlobalIcon',{attrs:{"icon":"check","color":"white"}})],1)]),_c('DialogAnalysisCommentUpdate')],2)}
var staticRenderFns = []

export { render, staticRenderFns }