import { useLocalStorage } from '@vueuse/core';
import { Ref } from '@vue/composition-api';
// Import helpers ----------------------------------------------------------------------------------
import { downloadPdf } from '@/helpers/helped-functions.helper';
// Import utils ------------------------------------------------------------------------------------
import { MediaType, useCDN } from '@/utils/cdn.utils';

const dontShowManualInterpretation = useLocalStorage('dontShowManualInterpretation', false);

export function useInterpretationManual(): {
  hide(): void;
  downloadInterpretationManual(analysisType: string, currentLang: string): void;
  dontShowManualInterpretation: Ref<boolean>;
} {
  function hide() {
    dontShowManualInterpretation.value = true;
  }

  function downloadInterpretationManual(analysisType: string, currentLang: string) {
    const { cdn } = useCDN();

    const analysisTypeCode = analysisType === 'walking' ? '010' : '020';
    const url = cdn(
      MediaType.Documents,
      `PS_0001_IFU_${analysisTypeCode}_REV02_${currentLang.toUpperCase()}.pdf`
    );
    const fileName = `interpretation-manual-${analysisType}`;

    downloadPdf(url, fileName);
  }

  return {
    dontShowManualInterpretation,
    hide,
    downloadInterpretationManual
  };
}
