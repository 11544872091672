import { render, staticRenderFns } from "./PatientResultsComments.vue?vue&type=template&id=76b4b534&scoped=true&"
import script from "./PatientResultsComments.vue?vue&type=script&lang=ts&"
export * from "./PatientResultsComments.vue?vue&type=script&lang=ts&"
import style0 from "./PatientResultsComments.vue?vue&type=style&index=0&id=76b4b534&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../common/temp/node_modules/.pnpm/vue-loader@15.9.8_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76b4b534",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../common/temp/node_modules/.pnpm/vuetify-loader@1.7.3_a266eb3209234f15fc02fe57f54d2cc5/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VHover } from 'vuetify/lib/components/VHover';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VBtn,VForm,VHover,VProgressCircular,VSnackbar,VTextarea,VTooltip})
