






// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartRunningGroupDetailled from '@/components/charts/running/groups/ChartRunningGroupDetailled.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsRunningAdvanced',
  components: {
    ChartRunningGroupDetailled
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    segments: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    scenario: {
      type: Object,
      required: true
    }
  }
});
