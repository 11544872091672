





































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, nextTick, onMounted, ref, watch } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useFormFieldText } from '@/utils/forms.utils';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import AlertError from '@/components/alerts/AlertError.vue';
import AlertSuccess from '@/components/alerts/AlertSuccess.vue';
import FormFieldTextArea from '@/components/forms/FormFieldTextArea.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'DialogAnalysisCommentUpdate',
  components: {
    CompositeDialog,
    AlertError,
    AlertSuccess,
    FormFieldTextArea
  },
  setup() {
    const dialogId: DialogId = 'analysis-comment-update';
    const rDialog = ref<any>(null);

    const requestModule = usePodocoreModule('request');
    const busModule = usePodocoreModule('bus');

    let commentRequest = ref<any>(null);
    let analysisCommentPatchRequest = ref<any>(null);

    const comment = useFormFieldText<string>({
      key: 'comment',
      value: '',
      rules: {}
    });

    onMounted(() => {
      watch(
        () => rDialog.value.displayContent,
        (value: boolean) => {
          if (value) {
            commentRequest.value = requestModule.useAuthenticatedRequest(
              `${apiConfig.default}/analysis/${rDialog.value.meta.analysisCuid}/comments/${rDialog.value.meta.commentCuid}`
            );
            analysisCommentPatchRequest.value = requestModule.useAuthenticatedRequest(
              `${apiConfig.default}/analysis/${rDialog.value.meta.analysisCuid}/comments/${rDialog.value.meta.commentCuid}`,
              {
                axios: {
                  method: 'PATCH'
                }
              }
            );

            commentRequest.value.request();
          } else {
            commentRequest.value.cancel();
            analysisCommentPatchRequest.value.cancel();

            nextTick(() => {
              commentRequest.value.clear();
              analysisCommentPatchRequest.value.clear();

              commentRequest.value = null;
            });
          }
        }
      );
    });

    function patchAnalysisComment() {
      analysisCommentPatchRequest.value.request({
        axios: {
          method: 'PATCH',
          data: {
            content: comment.value
          }
        }
      });
    }

    function closeDialog() {
      rDialog.value.close();
    }

    const isMounted = computed(() => {
      return commentRequest.value && analysisCommentPatchRequest.value;
    });

    const isDataMounted = computed(() => {
      return commentRequest.value?.data;
    });

    const isDisabled = computed(() => {
      return !commentRequest.value || commentRequest.value.isPending;
    });

    const isSuccess = computed(() => {
      return !analysisCommentPatchRequest.value?.error && !!analysisCommentPatchRequest.value?.data;
    });

    watch(isDataMounted, (value) => {
      if (value) {
        comment.value = commentRequest.value.data.content;
      }
    });

    watch(isSuccess, (value) => {
      if (value) {
        busModule.publish(
          busModule.events.analysisCommentPatched({
            analysisCommentCuid: analysisCommentPatchRequest.value.data.cuid
          })
        );
      }
    });

    return {
      comment,
      rDialog,
      dialogId,
      commentRequest,
      analysisCommentPatchRequest,
      // Methods
      patchAnalysisComment,
      closeDialog,
      // Flags
      isMounted,
      isDisabled,
      isSuccess
    };
  }
});
