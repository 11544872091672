


































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, toRefs, onMounted } from '@vue/composition-api';
import { get } from 'lodash';
// Import components -------------------------------------------------------------------------------
import FormFieldAutoComplete from '@/components/forms/FormFieldAutoComplete.vue';
// Import utils ------------------------------------------------------------------------------------
import { cleanFieldValue, useForm, useFormFieldAutoComplete, useFormObserver } from '@/utils/forms.utils';
// Import helpers ----------------------------------------------------------------------------------
import { enumToArrayAndTranslate } from '@/helpers/helped-functions.helper';
// Import types ------------------------------------------------------------------------------------
import {
  ConditionsShoesTypeCushioning,
  ConditionsShoesTypeRigidity,
  ConditionsShoesTypeWear
} from '@/plugins/podocore/repositories/analysis.repository';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsConditionsShoesTypeForm',
  components: { FormFieldAutoComplete },
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    conditions: {
      type: Object,
      default: null
    }
  },
  setup(properties, { emit }) {
    const rObserver = useFormObserver();

    const { conditions } = toRefs(properties);

    const rigidities = enumToArrayAndTranslate(ConditionsShoesTypeRigidity, {
      key: 'analysis.steps.conditions.shoes_type.rigidity'
    });
    const cushionings = enumToArrayAndTranslate(ConditionsShoesTypeCushioning, {
      key: 'analysis.steps.conditions.shoes_type.cushioning'
    });
    const wearList = enumToArrayAndTranslate(ConditionsShoesTypeWear, {
      key: 'analysis.steps.conditions.shoes_type.wear'
    });

    const rigidity = useFormFieldAutoComplete<string>({
      key: 'rigidity',
      value: null!,
      items: rigidities,
      rules: {
        required: true
      }
    });
    const cushioning = useFormFieldAutoComplete<string>({
      key: 'cushioning',
      value: null!,
      items: cushionings,
      rules: {
        required: true
      }
    });
    const wear = useFormFieldAutoComplete<string>({
      key: 'wearList',
      value: null!,
      items: wearList,
      rules: {
        required: false
      }
    });

    const { submit } = useForm(
      {
        rigidity,
        cushioning,
        wear
      },
      rObserver,
      () => {
        emit('submit', getFields());
      }
    );

    function getFields() {
      const data = {
        rigidity: cleanFieldValue(rigidity),
        cushioning: cleanFieldValue(cushioning),
        wearList: cleanFieldValue(wear)
      };
      return {
        conditions: {
          ...(conditions.value || { walkingAids: [], pains: [] }),
          shoesType: data
        },
        patchedShoesType: data
      };
    }

    onMounted(() => {
      if (conditions.value?.shoesType) {
        rigidity.value = get(conditions.value?.shoesType, 'rigidity');
        cushioning.value = get(conditions.value?.shoesType, 'cushioning');
        wear.value = get(conditions.value?.shoesType, 'wearList', []);
      }
    });

    return {
      // References
      rObserver,
      // Form fields
      rigidity,
      cushioning,
      wear,
      // Methods
      submit
    };
  }
});
