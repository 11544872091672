















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartRehabGroupGeneralParametersAdvanced from '@/components/charts/rehab/groups/ChartRehabGroupGeneralParametersAdvanced.vue';
import ElementResultsSwitch from '@/components/elements/ElementResultsSwitch.vue';
// Import utils ------------------------------------------------------------------------------------
import { useVuetifyBreakpoints } from '@/utils/vuetify-breakpoints.utils';
import { useCDN } from '@/utils/cdn.utils';
import { useRehab } from '@/utils/rehab.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRehabContainerTripleHopGeneralParameters',
  components: {
    ChartContainer,
    ChartRehabGroupGeneralParametersAdvanced,
    ElementResultsSwitch
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    segments: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: false
    },
    displayMetrics: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const { isLgAndLarger, isMdAndLarger } = useVuetifyBreakpoints();
    const { cdn } = useCDN();
    const { getTripleHopGeneralParametersSimplifiedData, getTripleHopGeneralParametersAdvancedData } =
      useRehab();

    const isAdvancedMode = ref(false);

    const simplifiedModeCharts = getTripleHopGeneralParametersSimplifiedData(properties.aggregates);
    const advancedModeCharts = getTripleHopGeneralParametersAdvancedData(
      properties.aggregates,
      properties.segments
    );

    return {
      // Values
      isAdvancedMode,
      isLgAndLarger,
      isMdAndLarger,
      simplifiedModeCharts,
      advancedModeCharts,
      // Methods
      cdn
    };
  }
});
