








// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartStandardSliderAdvanced from '@/components/charts/standard/ChartStandardSliderAdvanced.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartStandardContainerSliderAdvanced',
  components: {
    ChartContainer,
    ChartStandardSliderAdvanced
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
});
