
































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, PropType } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import PatientResultsErroneous from './PatientResultsErroneous.vue';
import PatientResultsWalkingDegraded from './PatientResultsWalkingDegraded.vue';
import PatientResultsWalkingGeneral from './PatientResultsWalkingGeneral.vue';
import PatientResultsWalkingAdvanced from './PatientResultsWalkingAdvanced.vue';
import PatientResultsWalkingPathology from './PatientResultsWalkingPathology.vue';
import PatientResultsWalkingCustom from './PatientResultsWalkingCustom.vue';
// Import types ------------------------------------------------------------------------------------
import { EFilter } from '@/components/patient/results/PatientResultsHeader.vue';
import { AnalysisEntityCompleted } from '@/plugins/podocore/repositories/analysis.repository';
import { EResultsTab } from '@/helpers/patient';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsWalking',
  components: {
    PatientResultsErroneous,
    PatientResultsWalkingDegraded,
    PatientResultsWalkingGeneral,
    PatientResultsWalkingAdvanced,
    PatientResultsWalkingPathology,
    PatientResultsWalkingCustom
  },
  props: {
    analysis: {
      type: Object as PropType<AnalysisEntityCompleted>,
      required: true
    },
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    resultsTab: {
      type: String as PropType<EResultsTab>,
      required: true
    },
    selectedFilter: {
      type: String as PropType<EFilter>,
      required: true
    },
    degradedAnalysis: {
      type: Boolean
    },
    validAnalysis: {
      type: Boolean
    },
    customSelectedCharts: {
      type: Array,
      required: false
    }
  }
});
