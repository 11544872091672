







































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, onBeforeMount, onBeforeUnmount, ref, computed } from '@vue/composition-api';
import { has } from 'lodash';
import moment from 'moment';
// Import components -------------------------------------------------------------------------------
import PatientMediasVideo from '@/components/patient/PatientMediasVideo.vue';
import AlertError from '@/components/alerts/AlertError.vue';
import DialogMediaDelete from '@/components/dialogs/DialogMediaDelete.vue';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import config -----------------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import types ------------------------------------------------------------------------------------
import { VideoEntityStatus } from '@digitsole/blackburn-entities/dist/entities/video/types/video.enums';
import type { PropType } from '@vue/composition-api';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultVideos',
  components: {
    PatientMediasVideo,
    AlertError,
    DialogMediaDelete
  },
  props: {
    activityCuidList: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  setup(properties) {
    const requestModule = usePodocoreModule('request');
    const busModule = usePodocoreModule('bus');

    const { request, isPending, error, cancel, data } = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/videos`,
      {
        axios: {
          params: {
            activityCuidList: properties.activityCuidList,
            latestStatuses: [
              VideoEntityStatus.AwaitingUpload,
              VideoEntityStatus.Processing,
              VideoEntityStatus.Completed
            ]
          }
        }
      }
    );

    const player = ref({
      0: false,
      1: false
    });

    busModule.useEventSubscriber(busModule.events.createNotification, ({ payload }) => {
      const message = payload.message;

      if (has(message, 'body.message.messageType')) {
        if (
          message.body.message.messageType.startsWith('video') &&
          properties.activityCuidList.find(
            (activityCuid) => activityCuid === message.body.message.data.activityCuid
          )
        ) {
          request();
        }
      }
    });

    const openDeleteMedia = (videoCuid: string) => {
      busModule.publish(
        busModule.events.openDialog({
          id: 'media-delete',
          meta: { videoCuid }
        })
      );
    };

    busModule.useEventSubscriber(busModule.events.mediaDeleted, () => {
      request();
    });

    onBeforeMount(() => {
      request();
    });

    onBeforeUnmount(() => {
      cancel();
    });

    const getFeet = (key: string) => {
      return key.endsWith('left') ? 'commons.standards.left' : 'commons.standards.right';
    };

    const videos = computed(() =>
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      data.value?.docs?.sort((a: any) => (a._scenarioStep.descriptionKey.endsWith('left') ? -1 : 1))
    );

    const minutesNextUpload = (video: any) => {
      return moment(new Date()).diff(moment(video.latestStatus.createdAt), 'minutes');
    };

    return {
      // Values
      player,
      videos,
      isPending,
      error,
      // Methods
      openDeleteMedia,
      minutesNextUpload,
      getFeet
    };
  }
});
