




















































































































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { chartColors } from '@/utils/charts-colors';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingFootProgressionAngle',
  props: {
    values: {
      type: Object,
      required: true
    },
    side: {
      type: String,
      required: false
    },
    simple: {
      type: Boolean
    }
  },
  setup() {
    return {
      chartColors
    };
  }
});
