















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartRehabContainerTripleHopJumpProfile from '@/components/charts/rehab/containers/ChartRehabContainerTripleHopJumpProfile.vue';
import ChartRehabContainerTripleHopGeneralParameters from '@/components/charts/rehab/containers/ChartRehabContainerTripleHopGeneralParameters.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsRehabTripleHop',
  components: {
    ChartRehabContainerTripleHopJumpProfile,
    ChartRehabContainerTripleHopGeneralParameters
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    segments: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: false
    }
  }
});
