









// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, PropType } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import PatientResultsErroneous from './PatientResultsErroneous.vue';
import PatientResultsRunningGeneral from './PatientResultsRunningGeneral.vue';
import PatientResultsRunningAdvanced from './PatientResultsRunningAdvanced.vue';
// Import types ------------------------------------------------------------------------------------
import type { AnalysisEntityCompleted } from '@/plugins/podocore/repositories/analysis.repository';
import { EResultsTab } from '@/helpers/patient';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsRunning',
  components: {
    PatientResultsErroneous,
    PatientResultsRunningGeneral,
    PatientResultsRunningAdvanced
  },
  props: {
    analysis: {
      type: Object as PropType<AnalysisEntityCompleted>,
      required: true
    },
    aggregates: {
      type: Object,
      required: true
    },
    segments: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    resultsTab: {
      type: String as PropType<EResultsTab>,
      required: true
    },
    validAnalysis: {
      type: Boolean
    },
    scenario: {
      type: Object,
      required: true
    }
  }
});
