










































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ElementChipNormValue from '@/components/elements/ElementChipNormValue.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningAsymmetry',
  components: {
    ElementChipNormValue
  },
  props: {
    asymmetry: {
      type: Object,
      required: true
    }
  }
});
