















// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartWalkingWalkProfileDegraded from '@/components/charts/walking/ChartWalkingWalkProfileDegraded.vue';
import ElementChartUnvailable from '@/components/elements/ElementChartUnvailable.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingContainerWalkProfileDegraded',
  components: {
    ChartWalkingWalkProfileDegraded,
    ElementChartUnvailable
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const valid = computed(() => properties.displayMetrics.aggregates.walking_stride_length);
    const analyticsData = {
      type: 'PatientAnalysis/General/Walking profile/tooltip',
      analysisType: 'walking'
    };

    return {
      // Valid
      valid,
      // Analytics
      analyticsData
    };
  }
});
