
















// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, ref } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartWalkingWalkProfile from '@/components/charts/walking/ChartWalkingWalkProfile.vue';
import ChartWalkingWalkProfileAdvanced from '@/components/charts/walking/ChartWalkingWalkProfileAdvanced.vue';
import ElementResultsSwitch from '@/components/elements/ElementResultsSwitch.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingContainerWalkProfile',
  components: {
    ChartContainer,
    ChartWalkingWalkProfile,
    ChartWalkingWalkProfileAdvanced,
    ElementResultsSwitch
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const valid = computed(
      () =>
        properties.displayMetrics.aggregates.walking_stride_length &&
        properties.displayMetrics.aggregates.walking_proportion_stance_time &&
        properties.displayMetrics.aggregates.walking_proportion_swing_time &&
        properties.displayMetrics.aggregates.walking_proportion_double_support_time
    );

    const advanced = ref(false);

    return {
      // Valid
      valid,
      // Values
      advanced
    };
  }
});
