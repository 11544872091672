












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartRunningRunProfile from '@/components/charts/running/ChartRunningRunProfile.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningContainerRunProfile',
  components: {
    ChartContainer,
    ChartRunningRunProfile
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const valid = computed(
      () =>
        properties.displayMetrics.aggregates?.running_proportion_stance_time &&
        properties.displayMetrics.aggregates?.running_proportion_swing_time &&
        properties.displayMetrics.aggregates?.running_stride_length &&
        properties.displayMetrics.aggregates?.running_proportion_flight_time
    );

    return {
      valid
    };
  }
});
