












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartRunningAsymmetry from '@/components/charts/running/ChartRunningAsymmetry.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningContainerAsymmetry',
  components: {
    ChartContainer,
    ChartRunningAsymmetry
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const valid = computed(() => properties.displayMetrics.aggregates?.running_stance_asymmetry);

    return {
      valid
    };
  }
});
