
























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useRehab } from '@/utils/rehab.utils';
// Import components -------------------------------------------------------------------------------
import PatientResultSummary from '@/components/patient/results/PatientResultSummary.vue';
import PatientResultVideos from '@/components/patient/results/PatientResultVideos.vue';
import PatientResultsRehabTripleHop from '@/components/patient/results/PatientResultsRehabTripleHop.vue';
import PatientResultsRehabCMJ from '@/components/patient/results/PatientResultsRehabCMJ.vue';
import PatientResultsRehabSingleHop from '@/components/patient/results/PatientResultsRehabSingleHop.vue';
import PatientResultsRehabSideHop from '@/components/patient/results/PatientResultsRehabSideHop.vue';
import ChartRehabContainerBanner from '@/components/charts/rehab/containers/ChartRehabContainerBanner.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsRehab',
  components: {
    PatientResultSummary,
    PatientResultVideos,
    PatientResultsRehabTripleHop,
    PatientResultsRehabCMJ,
    PatientResultsRehabSingleHop,
    PatientResultsRehabSideHop,
    ChartRehabContainerBanner
  },
  props: {
    analysis: {
      type: Object,
      required: true
    },
    aggregates: {
      type: Object,
      required: true
    },
    segments: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const { getMode, getActiveWidgets } = useRehab();

    const mode = getMode(properties.analysis._scenario.key);
    const activeWidgets = getActiveWidgets(mode);

    return {
      // Values
      mode,
      activeWidgets
    };
  }
});
