















































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, computed, toRefs, onMounted } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import FormFieldAutoComplete from '@/components/forms/FormFieldAutoComplete.vue';
import PatientResultsConditionsPainsFormLevel from '@/components/patient/results/conditions/PatientResultsConditionsPainsFormLevel.vue';
// Import plugins ----------------------------------------------------------------------------------
import { get } from 'lodash';
// Import utils ------------------------------------------------------------------------------------
import { cleanFieldValue, useForm, useFormFieldAutoComplete, useFormObserver } from '@/utils/forms.utils';
import {
  ConditionsPainLocations,
  ConditionsPainSide,
  ConditionsPainZone_1,
  ConditionsPainZone_2_C1,
  ConditionsPainZone_2_C2
} from '@/plugins/podocore/repositories/analysis.repository';
// Import helpers ----------------------------------------------------------------------------------
import { enumToArrayAndTranslate } from '@/helpers/helped-functions.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsConditionsShoesTypeForm',
  components: { FormFieldAutoComplete, PatientResultsConditionsPainsFormLevel },
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    conditions: {
      type: Object,
      default: null
    },
    painIndex: {
      type: Number,
      default: null
    },
    mode: {
      type: String,
      default: 'add'
    }
  },
  setup(properties, { emit }) {
    const rObserver = useFormObserver();

    const { conditions, painIndex, mode } = toRefs(properties);

    const locations: any = enumToArrayAndTranslate(ConditionsPainLocations, {
      key: 'analysis.steps.conditions.pains.location'
    });
    const sides: any = enumToArrayAndTranslate(ConditionsPainSide, {
      key: 'analysis.steps.conditions.pains.side'
    });
    const zones_1: any = enumToArrayAndTranslate(ConditionsPainZone_1, {
      key: 'analysis.steps.conditions.pains.zone_1'
    });
    const zones_2: any = computed(() => {
      switch (location.value) {
        case ConditionsPainLocations.Foot:
          return enumToArrayAndTranslate(ConditionsPainZone_2_C2, {
            key: 'analysis.steps.conditions.pains.zone_2'
          });
        default:
          return enumToArrayAndTranslate(ConditionsPainZone_2_C1, {
            key: 'analysis.steps.conditions.pains.zone_2'
          });
      }
    });

    const location = useFormFieldAutoComplete<string>({
      key: 'location',
      value: null!,
      items: locations,
      rules: {
        required: true
      }
    });
    const side = useFormFieldAutoComplete<string>({
      key: 'side',
      value: null!,
      items: sides,
      rules: {
        required: true
      }
    });
    const zone1 = useFormFieldAutoComplete<string>({
      key: 'zone1',
      value: null!,
      items: zones_1,
      rules: {
        required: true
      }
    });
    const zone2 = useFormFieldAutoComplete<string>({
      key: 'zone2',
      value: null!,
      items: zones_2,
      rules: {
        required: true
      }
    });
    const level = ref(0);

    const { submit } = useForm(
      {
        location,
        side,
        zone1,
        zone2,
        level
      },
      rObserver,
      () => {
        emit('submit', getFields());
      }
    );

    function getFields() {
      const data = {
        location: cleanFieldValue(location),
        side: cleanFieldValue(side),
        zone1: cleanFieldValue(zone1),
        zone2: cleanFieldValue(zone2),
        level: cleanFieldValue(level)
      };
      let painList = [...conditions.value.painList] || [];
      if (mode.value === 'add') {
        painList.push(data);
        return {
          conditions: {
            ...(conditions.value || { walkingAids: [] }),
            painList
          },
          patchedPain: data
        };
      } else {
        painList = painList.filter((x: any, index: number) => index != painIndex.value);
        painList.push(data);
        return {
          conditions: {
            ...(conditions.value || { walkingAids: [] }),
            painList
          },
          patchedPain: data
        };
      }
    }

    function setPainLevel(painLevel: number) {
      level.value = painLevel;
      emit('selectedPainLevel', painLevel);
    }

    onMounted(() => {
      if (conditions.value?.painList && painIndex.value != null) {
        const pain = conditions.value.painList[painIndex.value];
        location.value = get(pain, 'location', []);
        side.value = get(pain, 'side', []);
        zone1.value = get(pain, 'zone1', []);
        zone2.value = get(pain, 'zone2', []);
        level.value = pain.level != undefined ? pain.level : 0;
        if (mode.value === 'patch') emit('selectedPainLevel', level.value);
      }
      if (mode.value === 'add') emit('selectedPainLevel', null);
    });

    return {
      // References
      rObserver,
      // Form fields
      location,
      side,
      zone1,
      zone2,
      level,
      // Methods
      setPainLevel,
      submit
    };
  }
});
