














































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, computed, watch, onMounted, ComputedRef } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import PatientResultsHeader from './PatientResultsHeader.vue';
import PatientResultsScoring from './PatientResultsScoring.vue';
import PatientResultsOrthotics from './PatientResultsOrthotics.vue';
import PatientResultsWalking from './PatientResultsWalking.vue';
import PatientResultsRehab from './PatientResultsRehab.vue';
import PatientResultsRunning from './PatientResultsRunning.vue';
import PatientResultsFooter from './PatientResultsFooter.vue';
import DialogAnalysisConditionsPainsUpdate from '@/components/dialogs/DialogAnalysisConditionsPainsUpdate.vue';
import DialogAnalysisConditionsWalkingAidsUpdate from '@/components/dialogs/DialogAnalysisConditionsWalkingAidsUpdate.vue';
import DialogAnalysisConditionsShoesTypeUpdate from '@/components/dialogs/DialogAnalysisConditionsShoesTypeUpdate.vue';
// Import config ---------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import helpers ----------------------------------------------------------------------------------
import { isValidAnalysis, isDegradedAnalysis, getIsOnlyOrthotics } from '@/helpers/helped-functions.helper';
import { usePatientResultTab, EResultsTab } from '@/helpers/patient';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
import { usePodocoreModuleService } from '@/plugins/podocore';
import { useLocalStorage } from '@vueuse/core';
// Import utils ------------------------------------------------------------------------------------
import { usePatient } from '@/utils/patient.utils';
import { useSingleAnalysis } from '@/utils/single-analysis.utils';
// -------------------------------------------------------------------------------------------------

export enum EFilter {
  default = 'default',
  reAthleticism = 're-athleticism',
  neurological = 'neurological',
  traumatology = 'traumatology',
  custom = 'custom'
}

export default defineComponent({
  name: 'PatientResults',
  components: {
    PatientResultsHeader,
    PatientResultsScoring,
    PatientResultsOrthotics,
    PatientResultsWalking,
    PatientResultsRunning,
    PatientResultsRehab,
    PatientResultsFooter,
    DialogAnalysisConditionsPainsUpdate,
    DialogAnalysisConditionsWalkingAidsUpdate,
    DialogAnalysisConditionsShoesTypeUpdate
  },
  props: {
    scenario: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const { data: patient } = usePatient();

    const { setSingleAnalysis, singleAnalysis } = useSingleAnalysis();

    const workspacesService = usePodocoreModuleService('workspaces');

    const busModule = usePodocoreModule('bus');
    const requestModule = usePodocoreModule('request');
    const aclService = usePodocoreModuleService('acl');

    const { resultsTab, isOnlyOrthotics } = usePatientResultTab(aclService);

    const analysisPatchFilterRequest = ref<any>(null);

    const workspace = computed(() => (workspacesService.state.value as any).context.current);
    const analysisType = computed(() => properties.scenario.key);

    const showScoringWidget = useLocalStorage('showScoringWidget', null);

    const orthoticsView = computed(() => resultsTab.value === EResultsTab.Orthotics);
    const scoringView = computed(() => resultsTab.value === EResultsTab.Scoring);
    const isWalking = computed(() => analysisType.value.startsWith('walking'));
    const isRunning = computed(() => analysisType.value.startsWith('running'));
    const isRehab = computed(() => analysisType.value.startsWith('jumping'));

    const displayMetrics: ComputedRef<any> = computed(() => (singleAnalysis.value as any)?.display_metrics);
    const aggregates = computed(() => (singleAnalysis.value as any)?.metrics?.aggregates);
    const segments = computed(() => (singleAnalysis.value as any)?.metrics?.segment);
    const norms = computed(() => (singleAnalysis.value as any)?.metrics?.norms);
    const recommendations = computed(() => (singleAnalysis.value as any)?.metrics?.recommendations);
    const validAnalysis: ComputedRef<boolean> = computed(() => isValidAnalysis(singleAnalysis.value));
    const degradedAnalysis: ComputedRef<boolean> = computed(() => isDegradedAnalysis(singleAnalysis.value));

    function setScoringWidget(status: boolean) {
      showScoringWidget.value = status;
    }

    // Walking filter
    const selectedFilter = ref(EFilter.default);
    function setSelectedFilter(_filter: EFilter) {
      selectedFilter.value = _filter;
      resultsTab.value = isOnlyOrthotics.value ? EResultsTab.Orthotics : EResultsTab.General;
    }
    // Walking custom charts
    const customSelectedCharts = ref([]);
    function setSelectedCharts(charts: any) {
      customSelectedCharts.value = charts;
    }

    // Analysis filters
    const analysisWorkspaceFilter = computed(() =>
      workspace.value.filters.find((x: any) => x.cuid === singleAnalysis.value.filter)
    );

    busModule.useEventSubscriber(busModule.events.analysisFilter, (event) => {
      setSelectedFilter(event.payload.filter as EFilter);
      setSelectedCharts(event.payload.selectedCharts ?? []);
      if (event.payload.selectedCharts) {
        if (event.payload.cuid) setAnalysisFilter(event.payload.cuid);
        setWorkspaceFilter(event.payload.type, event.payload);
      } else {
        setAnalysisFilter(event.payload.filter);
      }
    });

    function setAnalysisFilter(filter: string) {
      analysisPatchFilterRequest.value = requestModule.useAuthenticatedRequest(
        `${apiConfig.default}/analysis/${singleAnalysis.value.cuid}/filter`,
        {
          axios: {
            method: 'PATCH',
            data: {
              filter: filter
            }
          }
        }
      );
      analysisPatchFilterRequest.value.request();
    }

    function setWorkspaceFilter(requestType: 'PATCH' | 'CREATE', payload: any) {
      if (requestType === 'PATCH') {
        workspacesService.send({
          type: 'PATCH_FILTER',
          data: {
            filterCuid: payload.cuid,
            filter: {
              name: payload.name,
              filters: payload.selectedCharts
            }
          }
        } as any);
      }
      if (requestType === 'CREATE') {
        workspacesService.send({
          type: 'CREATE_FILTER',
          data: {
            name: payload.name,
            filters: payload.selectedCharts
          }
        } as any);
      }
    }

    const isSuccessFilterCreated = computed(() =>
      [{ creatingFilter: 'success' }].some((element) => workspacesService.state.value.matches(element))
    );
    const isSuccessFilterPatched = computed(() =>
      [{ patchingFilter: 'success' }].some((element) => workspacesService.state.value.matches(element))
    );
    const isSuccessFilterDeleted = computed(() =>
      [{ deletingFilter: 'success' }].some((element) => workspacesService.state.value.matches(element))
    );

    watch(isSuccessFilterCreated, (value) => {
      if (value && workspace.value?.filters?.length) {
        setAnalysisFilter(workspace.value.filters[workspace.value.filters.length - 1].cuid);
      }
    });

    watch(analysisPatchFilterRequest, (value) => {
      if (value && value.data && !value.error && (value.data as any)?.filter?.filter) {
        setSingleAnalysis({ ...singleAnalysis.value, filter: (value.data as any).filter.filter });
      }
    });

    onMounted(() => {
      if (singleAnalysis.value.filter)
        if (Object.values(EFilter).includes(singleAnalysis.value.filter as EFilter)) {
          // Is pathology filter type
          selectedFilter.value = singleAnalysis.value.filter as EFilter;
        }
        // Is custom filter type
        else if (analysisWorkspaceFilter.value) {
          selectedFilter.value = EFilter.custom;
          setSelectedCharts(analysisWorkspaceFilter.value.filters);
        }
    });

    return {
      analysis: singleAnalysis.value,
      validAnalysis,
      degradedAnalysis,
      // Values
      selectedFilter,
      customSelectedCharts,
      resultsTab,
      patient,
      aggregates,
      segments,
      norms,
      recommendations,
      workspace,
      displayMetrics,
      analysisType,
      // Flags
      scoringView,
      showScoringWidget,
      orthoticsView,
      isWalking,
      isRunning,
      isRehab,
      isOnlyOrthotics,
      isSuccessFilterCreated,
      isSuccessFilterPatched,
      isSuccessFilterDeleted,
      // Methods
      setScoringWidget,
      setSelectedCharts,
      setSelectedFilter
    };
  }
});
