




















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartRehabSideHopCadenceEvolution from '@/components/charts/rehab/ChartRehabSideHopCadenceEvolution.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRehabContainerSideHopCadenceEvolution',
  components: {
    ChartContainer,
    ChartRehabSideHopCadenceEvolution
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    segments: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: false
    }
  }
});
