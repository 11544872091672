























// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, ref } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartRunningGroupStepsAngles from '@/components/charts/running/groups/ChartRunningGroupStepsAngles.vue';
import ChartRunningPronationSupinationAnglesAdvanced from '@/components/charts/running/ChartRunningPronationSupinationAnglesAdvanced.vue';
import ElementResultsSwitch from '@/components/elements/ElementResultsSwitch.vue';
import ElementResultsSwitchRelative from '@/components/elements/ElementResultsSwitchRelative.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningContainerGroupStepsAngles',
  components: {
    ChartContainer,
    ChartRunningGroupStepsAngles,
    ChartRunningPronationSupinationAnglesAdvanced,
    ElementResultsSwitch,
    ElementResultsSwitchRelative
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const advancedAngles = computed(
      () =>
        properties.displayMetrics.aggregates?.running_pronation_angle_foot_in &&
        properties.displayMetrics.aggregates?.running_pronation_angle_foot_out &&
        properties.displayMetrics.aggregates?.running_pronation_angle_mid
    );
    const simplifiedAngles = computed(
      () =>
        properties.displayMetrics.aggregates?.running_pronation_angle_foot_in &&
        properties.displayMetrics.aggregates?.running_pronation_angle_foot_out &&
        !properties.displayMetrics.aggregates?.running_pronation_angle_mid
    );
    const validAngles = computed(
      () =>
        properties.displayMetrics.aggregates?.running_pronation_angle_foot_in &&
        properties.displayMetrics.aggregates?.running_pronation_angle_foot_out
    );
    const validPhases = computed(
      () =>
        properties.displayMetrics.aggregates?.running_propulsion_time &&
        properties.displayMetrics.aggregates?.running_proportion_propulsion_time &&
        properties.displayMetrics.aggregates?.running_loading_time &&
        properties.displayMetrics.aggregates?.running_proportion_loading_time
    );

    const relative = ref(false);
    const advanced = ref(false);

    return {
      // Valid
      validAngles,
      advancedAngles,
      simplifiedAngles,
      validPhases,
      // Values
      relative,
      advanced
    };
  }
});
