











































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, nextTick, onMounted, ref, watch } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import AlertError from '@/components/alerts/AlertError.vue';
import AlertSuccess from '@/components/alerts/AlertSuccess.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'DialogMediaDelete',
  components: { CompositeDialog, AlertError, AlertSuccess },
  setup() {
    const dialogId: DialogId = 'media-delete';
    const rDialog = ref<any>(null);

    const requestModule = usePodocoreModule('request');
    const busModule = usePodocoreModule('bus');

    let mediaDeleteRequest = ref<any>(null);

    onMounted(() => {
      watch(
        () => rDialog.value.displayContent,
        (value: boolean) => {
          if (value) {
            mediaDeleteRequest.value = requestModule.useAuthenticatedRequest(
              `${apiConfig.default}/videos/${rDialog.value.meta.videoCuid}`,
              {
                axios: {
                  method: 'DELETE'
                }
              }
            );
          } else {
            mediaDeleteRequest.value.cancel();

            nextTick(() => {
              mediaDeleteRequest.value.clear();
            });
          }
        }
      );
    });

    function deleteActivity() {
      mediaDeleteRequest.value.request();
    }

    function closeDialog() {
      rDialog.value.close();
    }

    const isMounted = computed(() => mediaDeleteRequest.value);

    const isSuccess = computed(() => {
      return !mediaDeleteRequest.value?.error && !!mediaDeleteRequest.value?.data;
    });

    watch(isSuccess, (value) => {
      if (value) {
        busModule.publish(
          busModule.events.mediaDeleted({
            mediaCuid: rDialog.value.meta.mediaCuid
          })
        );
      }
    });

    return {
      rDialog,
      dialogId,
      mediaDeleteRequest,
      // Methods
      deleteActivity,
      closeDialog,
      // Flags
      isMounted,
      isSuccess
    };
  }
});
